import React from 'react'
import {Upload, Input, Form, Row, Col} from 'antd'
import PlusOutlined from '@ant-design/icons/PlusOutlined'

import './FileUpload.scss'
import ModalContent from '../../Modal'
import {detailFile, openMessage} from '../../Helper'
import {FileService} from '../../../services'
import {NotificationGenerator} from '../../NotificationGenerator'

export default class FileUpload extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      fileList: [],

      file: null,
      fileName: '',
      previewImage: '',
      previewTitle: '',
      previewVisible: false
    }
  }

  open = () => {
    this.setState({previewVisible: true})
  }

  onChange = info => {
    const {fileList, 'file': {status}} = info

    switch (status) {
      case 'uploading':
        this.setState({fileList})
        break
      case 'done':
        // Notifie du succès
        NotificationGenerator.raise(`"${info.file.name}" ajouté.`, 'success')
        // Ajoute le fichier dans le store
        // this.props.actions.addFile(info.file.response.data)
        // Retire le fichier de la liste
        this.setState({
          fileList: fileList.filter((file) => file !== info.file)
        })
        break
      case 'error':
        // Notifie de l'erreur
        NotificationGenerator.raise(`"${info.file.name}" n'a pas pu être envoyé.`, 'error')
        // Retire le fichier de la liste
        this.setState({fileList: fileList.filter((file) => file !== info.file)})
        break
      default:
        break
    }
  }

  handleUpload = file => {
    this.props.handleLoading(true)
    const data = new FormData()

    data.append('file', file)
    data.append('name', this.state.fileName || file.name)

    FileService.store(data, {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'})
      .then(async () => {
        await openMessage({content: 'Upload...', contentAfter: 'Le chargement du fichier est un succès.'})
      })
      .catch(async () => {
        await openMessage({content: 'Upload...', contentAfter: 'Le chargement du fichier a échoué.', type: 'error', duration: 5})
      })
      .finally(() => {
        this.props.handleLoading(false)
        this.props.onRefresh()
      })
  }

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  handleCancel = () => this.setState({
    file: null,
    fileList: [],
    fileName: '',
    previewImage: '',
    previewTitle: '',
    previewVisible: false
  })

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    })
  }

  handleChange = ({fileList}) => this.setState({fileList})

  customRequest = async ({file}) => this.setState({file})

  handleOk = () => {
    this.handleUpload(this.state.file)
    this.handleCancel()
  }

  render () {
    const {file, fileName, fileList, previewVisible} = this.state

    const uploadButton = (
      <div>
        <PlusOutlined/>
        <div style={{marginTop: 8}}>Charger</div>
      </div>
    )

    return (
      <div className="maw-file-upload">
        {previewVisible && <ModalContent
          title="Nouveau Fichier"
          visible={previewVisible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          disabledOkButton={!file || fileName === ''}
        >
          <div hidden>{fileName}</div>
          <div>
            <Form.Item
              label="Nom du fichier"
              name="fileName"
              rules={[
                {
                  required: true,
                  message: 'Saisir le nom du ficher!'
                }
              ]}
            >
              <div hidden>{fileName}</div>
              <Input
                allowClear
                value={fileName}
                placeholder="Nom du fichier"
                onChange={e => this.setState({fileName: e.target.value})}
              />
            </Form.Item>
            <Form.Item
              name="file"
              label="Image/Vidéo"
              rules={[{required: true, message: 'Image/Vidéo'}]}
            >
              <Upload
                name="file"
                fileList={fileList}
                multiple={false}
                data={{return: 1}}
                listType="picture-card"
                accept="video/*,image/*"
                onChange={this.handleChange}
                onPreview={this.handlePreview}
                showFileList={fileList.length > 0}
                customRequest={this.customRequest}
              >
                {fileList.length < 1 && uploadButton}
              </Upload>
            </Form.Item>
            {file && (
              <Row>
                <Col span={8}>Nom: {file.name}</Col>
                <Col span={8}>Taille: {detailFile(file.size)}</Col>
                <Col span={8}>Type: {file.type}</Col>
              </Row>
            )}
          </div>
        </ModalContent>}

      </div>
    )
  }
}
