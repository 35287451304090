import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Skeleton, List, Divider, message, Row, Col, Card, Form, Input, Button, Checkbox} from 'antd'

import './index.css'
import {LoginService} from '../../../services'
import {Notification} from '../../Profile/UserForm'

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16
  }
}

export default function LoginPage () {
  const date = new Date()
  const history = useHistory()
  const location = useLocation()

  const [entities, setEntities] = useState([])
  const [loading, setLoading] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationType, setNotificationType] = useState('success')

  useEffect(() => {
    (async () => {
      await LoginService.check() && history.push('/files')
    })()
  }, [history])

  const login = () => {
    let {from} = location.state || {from: {pathname: '/files'}}
    history.replace(from)
  }

  const successLogin = async ({data}) => {
    LoginService.setToken(data.token)
    await LoginService.getUserFromApi().then((res) => LoginService.setUser(res.data))

    if (data.entities.length > 1) {
      setEntities(data.entities)
    } else {
      login()
      await openMessage()
    }
  }

  const errorLogin = error => {
    setShowNotification(true)
    setNotificationType('error')

    let msg = ''
    if (error.response.status === 422) {
      let errors = error.response.data.errors
      msg = errors.email ? errors.email.join(' ') : errors.password.join(' ')
    } else {
      msg = error.response.data.message
    }
    setNotificationMessage(msg)
    setLoading(false)
  }

  const onFinish = async ({email, password, remember}) => {
    setLoading(true)
    await LoginService.attemptLogin({email, password, remember})
      .then(successLogin)
      .catch(errorLogin)
  }

  const openMessage = async () => {
    const key = 'updatable'
    await message.loading({content: 'Connexion...', key, duration: .5}).then(async () => {
      await message.success({content: 'Connecté!', key, duration: 3})
    })
  }

  const onSelectEntity = async () => {
    await LoginService.getUserFromApi().then(res => {
      LoginService.setUser(res.data)
      login()
      openMessage().then()
    })
  }

  const pathImage = () => `${window.location.origin}/logosignage.png`

  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      style={{minHeight: '100vh'}}
    >
      <Col>
        <Card
          title={
            <Divider style={{marginButton: showNotification ? '1rem 0' : ''}}>
              <img
                alt={pathImage()}
                src={pathImage()}
                style={{width: '100%', height: '100%'}}
              />
            </Divider>
          }
          loading={loading}
          style={{width: 530}}
        >
          {entities.length === 0 && (
            <div>
              <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                initialValues={{
                  remember: false,
                  email: process.env.NODE_ENV === 'production' ? '' : 'senit@dev.com',
                  password: process.env.NODE_ENV === 'production' ? '' : 'secret'
                }}
              >
                {showNotification && (
                  <div style={{marginBottom: '1.5rem'}}>
                    <Notification
                      type={notificationType}
                      message={notificationMessage}
                    />
                  </div>
                )}

                <Form.Item
                  name="email"
                  hasFeedback
                  label="Adresse email"
                  rules={[
                    {required: true, message: 'Veuillez saisir votre adresse email!'},
                    {type: 'email', message: 'Veuillez saisir une adresse email valide!'}
                  ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                  hasFeedback
                  name="password"
                  label="Mot de passe"
                  rules={[
                    {required: true, message: 'Veuillez saisir votre mot de passe!'}
                  ]}
                >
                  <Input.Password/>
                </Form.Item>

                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                  <Checkbox>Se souvenir de moi</Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button className="login-form-button" type="primary" htmlType="submit" loading={loading}>
                    Connexion
                  </Button>
                </Form.Item>
              </Form>
              <Divider/>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span>© Copyright {date.getFullYear()} <strong>Senit Dev</strong></span>
                <span>Version: 0.2.0 - {`${date.toLocaleDateString()}`}</span>
              </div>
            </div>
          )}

          {entities.length > 1 && <>
            <Divider orientation="center">
              Sélectionnez une entitée
            </Divider>
            <List
              loading={false}
              loadMore={null}
              dataSource={entities}
              itemLayout="horizontal"
              className="demo-loadmore-list"
              renderItem={item => (
                <List.Item actions={[
                  <Button
                    type="link"
                    key="list-loadmore-more"
                    onClick={() => onSelectEntity(item)}
                  >Sélectionner</Button>
                ]}>
                  <Skeleton loading={false}>
                    <List.Item.Meta
                      title={item.name}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </>}
        </Card>
      </Col>
    </Row>
  )
}
