import PropTypes from 'prop-types'
import React, {useState, useEffect, memo, useCallback} from 'react'
import {EditOutlined, MailOutlined, AppstoreOutlined} from '@ant-design/icons'
import {Typography, Menu, Space, Form, Input, Button, Checkbox, Tooltip, Row, Col} from 'antd'

import TitleBar from '../../../../Shared/TitleBar'
import ModalContent from '../../../../Shared/Modal'
import {useFetch} from '../../../../utils/UseFetchData'
import MediaTable from '../../../../Shared/MediaTable'
import routesBackend from '../../../../utils/routeBackend'
import {openMessage, reload} from '../../../../Shared/Helper'
import {LoginService, ProfileService} from '../../../../services'
import EditableCell from '../../../../Shared/DataTable/EditableCell/EditableCell'

const ACTION = {
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  MODULE_USER: 'MODULE_USER',
  PERMISSION_USER: 'PERMISSION_USER'
}
const {Text} = Typography

export default class UserTable extends React.Component {
  static propTypes = {
    onNameEdit: PropTypes.func
  }

  static ColumnModel = {
    // Modèle pour le champ "id"
    id: {
      title: <input onClick={this.selectAll} id="selectall" type="checkbox"/>,
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => b.id - a.id,
      render: (text, user) => <input className="ads_Checkbox" value={user.id} type="checkbox"/>
    },
    name: (onEdit = null) => {
      const render = onEdit ?
        (text, user) => <EditableCell record={user} field="name" onEdit={onEdit}/>
        : (text, user) => `${user.first_name} ${user.last_name}`

      return {
        title: 'Nom',
        dataIndex: 'name',
        key: 'name',
        searchable: true,
        sorter: (a, b) => `${b.first_name}${b.last_name}` - `${a.first_name}${a.last_name}`,
        render
      }
    },
    phone: {
      title: 'Téléphone',
      key: 'phone',
      sorter: (a, b) => b.phone - a.phone,
      render: (text, user) => `${user.phone} ${user.state}`
    },
    email: {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => b.email.localeCompare(a.email),
      render: (text, user) => user.email
    },
    type: {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      sorter: (a, b) => b.type.localeCompare(a.type),
      render: (text, user) => user.type
    },
    module: (onEditModule) => {
      return {
        key: 'module',
        title: 'Module',
        dataIndex: 'module',
        render: (text, user) => <Tooltip
          title="Droit"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            icon={<EditOutlined/>}
            onClick={(text, user) => onEditModule(user)}
          />
        </Tooltip>
      }
    },
    permission: (onEditPermission) => {
      return {
        key: 'permission',
        title: 'Droit',
        dataIndex: 'permission',
        render: (text, user) => <Tooltip
          title="Droit"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            icon={<EditOutlined/>}
            onClick={() => onEditPermission(text, user)}
          />
        </Tooltip>
      }
    },
    // Modèle pour le champ "createdAt"
    createdAt: {
      title: 'Date de création',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => b.created_at - a.created_at,
      render: (text, user) => user.created_at
    }
  }

  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      showModal: false,
      user: {
        id: null,
        type: null,
        name: null,
        phone: null,
        email: null,
        password: null,
        last_name: null,
        first_name: null,
        updated_at: null,
        created_at: null
      },
      titleModal: 'Ajout',
      notification: {
        show: false,
        type: 'success',
        message: null
      },
      actionToShow: ACTION.ADD_USER
    }
  }

  handleOpenModal = actionToShow => this.setState({
    actionToShow,
    showModal: true
  })

  onEditPermission = (text, user) => {
    this.setState(
      {
        titleModal: 'Droit',
        user
      },
      () => {
        this.handleOpenModal(ACTION.PERMISSION_USER)
      }
    )
  }

  handleCloseModal = () => {
    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        id: null,
        name: null,
        type: null,
        phone: null,
        email: null,
        password: null,
        last_name: null,
        first_name: null,
        updated_at: null,
        created_at: null
      },
      showModal: false,
      actionToShow: ACTION.ADD_USER
    }))
  }

  onEdit = user => this.setState({user, titleModal: 'Editer'}, () => this.handleOpenModal(ACTION.EDIT_USER))

  onAdd = () => {
    this.setState({titleModal: 'Ajout'}, () => {
      this.handleOpenModal(ACTION.ADD_USER)
    })
  }

  handleOk = () => {

  }

  onAddUser = values => {
    this.setState({loading: true})

    LoginService.register(values).then(async ({data}) => {
      this.setState({showModal: false}, async () => {
          await openMessage({content: 'Ajout...', contentAfter: data.message})
          this.props.onRefresh()
        }
      )
    }).catch(async ({response}) => {
      const message = this.showErrors(response.data.errors)

      this.setState(prevState => ({
        ...prevState,
        notification: {
          show: true,
          type: 'error',
          message
        }
      }))
      await openMessage({content: 'Ajout...', contentAfter: message})
    }).finally(async () => setTimeout(() => {
      this.setState({loading: false})
    }, 1500))
  }

  onUpdateUser = values => {
    this.setState({loading: true})

    ProfileService.updateProfile(this.state.user.id, values).then(async ({data}) => {
      this.setState(prevState => ({
        ...prevState,
        showModal: false,
        notification: {
          show: true,
          message: data.message
        }
      }), async () => {
        await openMessage({content: 'Mise à jour...', contentAfter: data.message})
        this.props.onRefresh()
      })
    }).catch(async ({response}) => {
      const {errors} = response.data
      if (errors.length > 0) {
        await openMessage({content: 'Mise à jour...', contentAfter: this.showErrors(errors)})
      }
    }).finally(async () => setTimeout(() => {
      this.setState({loading: false})
    }, 1000))
  }

  showErrors = errors => {
    let msg = ''
    if (errors.email) msg = errors.email.join(' ')
    else if (errors.first_name) msg = errors.first_name.join(' ')
    else if (errors.last_name) msg = errors.last_name.join(' ')
    else if (errors.phone) msg = errors.phone.join(' ')
    else if (errors.password) msg = errors.password.join(' ')
    return msg
  }

  onEditModule = () => {
    this.setState({titleModal: 'Module'}, () => {
      this.handleOpenModal(ACTION.MODULE_USER)
    })
  }

  render () {
    const columns = [
      UserTable.ColumnModel.name(this.props.onNameEdit),
      UserTable.ColumnModel.email,
      UserTable.ColumnModel.type,
      UserTable.ColumnModel.phone,
      UserTable.ColumnModel.module(this.onEditModule),
      UserTable.ColumnModel.permission(this.onEditPermission),
      UserTable.ColumnModel.createdAt
    ]
    const {openPreview, dataSource, onRefresh} = this.props
    const {titleModal, showModal, loading, notification, user, actionToShow} = this.state

    return (
      <div>
        {/*TItle content*/}
        <TitleBar title="Utilisateurs"/>

        {/*page content*/}
        <MediaTable
          {...this.props}
          columns={columns}
          onEdit={this.onEdit}
          onAdd={this.onAdd}
          onRefresh={onRefresh}
          onPreview={openPreview}
          dataSource={dataSource}
        />

        {/*Modal to Add or Edit info user*/}
        {showModal && <ModalContent
          style={null}
          width={590}
          footer={null}
          title={titleModal}
          visible={showModal}
          handleOk={this.handleOk}
          handleCancel={this.handleCloseModal}
        >
          <div>
            {actionToShow === ACTION.ADD_USER && <UserFormHelper
              loading={loading}
              initialValues={{
                id: null,
                type: null,
                name: null,
                phone: null,
                email: null,
                password: null,
                last_name: null,
                first_name: null,
                updated_at: null,
                created_at: null
              }}
              requiredPassword={true}
              onSubmit={this.onAddUser}
              style={{marginTop: notification.show ? '1.5rem' : ''}}
            />}
            {actionToShow === ACTION.EDIT_USER && <UserFormHelper
              loading={loading}
              initialValues={user}
              submitText="Modifier"
              requiredPassword={true}
              onSubmit={this.onUpdateUser}
              style={{marginTop: notification.show ? '1.5rem' : ''}}
            />}
            {actionToShow === ACTION.MODULE_USER && <ModuleFormHelper/>}
            {actionToShow === ACTION.PERMISSION_USER && <PermissionFormHelper
              userId={user.id}
              permissions={user.permissions}
              handleCloseModal={this.handleCloseModal}
            />}
          </div>
        </ModalContent>}
      </div>
    )
  }
}

let initialState = {
  create_file: false,
  update_file: false,
  delete_file: false,
  create_scene: false,
  update_scene: false,
  delete_scene: false,
  create_agenda: false,
  update_agenda: false,
  delete_agenda: false,
  create_module: false,
  update_module: false,
  delete_module: false
}

const ModuleFormHelper = memo(() => {
  const [current, setCurrent] = useState('module')

  const handleClick = e => setCurrent(e.key)

  return (
    <div>
      <Menu style={{marging: 0}} onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="module">
          Module
        </Menu.Item>
        <Menu.Item key="scene">
          Groupes
        </Menu.Item>
      </Menu>

      <div style={{margin: '1rem 1rem'}}>
        <Row>
          <Col>
            <Button type="primary" htmlType="submit">OK</Button>
            &nbsp;&nbsp;<Text type="warning">En cours de developpement</Text>
          </Col>
        </Row>
      </div>
    </div>
  )
})

const PermissionFormHelper = memo(({permissions, userId, handleCloseModal}) => {
  const [formData, setFormData] = useState(initialState)
  const [current, setCurrent] = useState('file')
  const {loading: loadingPermission, postData: syncPermissions} = useFetch()

  const transformArrayToObject =  useCallback((arr) => {
    let result = {}
    for (let i = 0; i < arr.length; i++) {
      result[arr[i]] = true
    }
    return result
  }, [])


  useEffect(() => {
    setFormData(
      transformArrayToObject(permissions)
    )
  }, [permissions, transformArrayToObject])

  const handleClick = e => setCurrent(e.key)

  const onFinish = () => {
    const data = {
      permissions: formData,
      user_id: userId
    }

    syncPermissions(routesBackend.USER_PERMISSION.INDEX, data).then(async ({data}) => {
      let result = {}
      for (let i = 0; i < data.permissions.length; i++) {
        result[data.permissions[i]] = true
      }
      setFormData(result)

      await openMessage({content: 'Mise à jour', contentAfter: 'Mise à jour effectuée avec succès.'})
      reload()
    }).catch(console.error)
  }

  return (
    <div>
      <Menu style={{marging: 0}} onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="file" icon={<MailOutlined/>}>
          Fichier
        </Menu.Item>
        <Menu.Item key="scene" icon={<AppstoreOutlined/>}>
          Scenes
        </Menu.Item>
        <Menu.Item key="agenda" icon={<AppstoreOutlined/>}>
          Agendas
        </Menu.Item>
        <Menu.Item key="module" icon={<AppstoreOutlined/>}>
          Modules
        </Menu.Item>
      </Menu>

      <Form
        onFinish={onFinish}
        preserve={false}
      >
        <Row style={{margin: '1rem 1rem'}}>
          {current === 'file' && <Row>
            <Col>
              <Checkbox
                checked={formData.create_file}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  create_file: e.target.checked
                }))}
              >Créer un Fichier</Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={formData.update_file}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  update_file: e.target.checked
                }))}
              >Modifier un Fichier</Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={formData.delete_file}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  delete_file: e.target.checked
                }))}>Supprimer un Fichier</Checkbox>
            </Col>
          </Row>}
          {current === 'scene' && <Row>
            <Col>
              <Checkbox
                checked={formData.create_scene}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  create_scene: e.target.checked
                }))}>Créer une Scene</Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={formData.update_scene}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  update_scene: e.target.checked
                }))}>Modifier une Scene</Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={formData.delete_scene}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  delete_scene: e.target.checked
                }))}>Supprimer une Scene</Checkbox>
            </Col>
          </Row>}
          {current === 'agenda' && <Row>
            <Col>
              <Checkbox
                checked={formData.create_agenda}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  create_agenda: e.target.checked
                }))}>Créer un Agenda</Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={formData.update_agenda}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  update_agenda: e.target.checked
                }))}>Modifier un Agenda</Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={formData.delete_agenda}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  delete_agenda: e.target.checked
                }))}>Supprimer un Agenda</Checkbox>
            </Col>
          </Row>}
          {current === 'module' && <Row>
            <Col>
              <Checkbox
                checked={formData.create_module}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  create_module: e.target.checked
                }))}>Créer un Module</Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={formData.update_module}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  update_module: e.target.checked
                }))}>Modifier l'état d'un Module</Checkbox>
            </Col>
            <Col>
              <Checkbox
                checked={formData.delete_module}
                onChange={(e) => setFormData(state => ({
                  ...state,
                  delete_module: e.target.checked
                }))}>Supprimer un Module</Checkbox>
            </Col>
          </Row>}
        </Row>

        <Row style={{margin: '1rem 1rem'}}>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit" loading={loadingPermission}>OK</Button>
              <Button htmlType="button" onClick={handleCloseModal}>Annuler</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  )
})

const UserFormHelper = memo(({loading, onSubmit, submitText = 'Ajouter', style, initialValues, requiredPassword = false}) => {
  return (
    <Form
      name="basic"
      style={style}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <Form.Item
        name="last_name"
        hasFeedback
        rules={[
          {required: true, message: 'Veuillez saisir le nom de l\'ulistateur!'}
        ]}
      >
        <Input placeholder="Nom"/>
      </Form.Item>
      <Form.Item
        name="first_name"
        hasFeedback
        rules={[
          {required: true, message: 'Veuillez saisir le prénom de l\'ulilisateur!'}
        ]}
      >
        <Input placeholder="Prénom"/>
      </Form.Item>
      <Form.Item
        name="phone"
        hasFeedback
        rules={[
          {required: true, message: 'Veuillez saisir le numéro de téléphone!'}
        ]}
      >
        <Input placeholder="Téléphone"/>
      </Form.Item>
      <Form.Item
        name="email"
        hasFeedback
        rules={[
          {required: true, message: 'Veuillez saisir votre adresse email!'},
          {type: 'email', message: 'Veuillez saisir une adresse email valide!'}
        ]}
      >
        <Input placeholder="Adresse email"/>
      </Form.Item>
      {requiredPassword && <Form.Item
        name="password"
        hasFeedback
        rules={[
          {required: requiredPassword, message: 'Veuillez saisir le mot de passe de l\'utilisateur!'}
        ]}
      >
        <Input.Password placeholder="Mot de passe"/>
      </Form.Item>}
      <Form.Item
        name="is_admin"
        // label="Administrateur"
        valuePropName="checked"
      >
        <Row>
          <Col>
            {'Administrateur: '} <Checkbox>OUI/NON</Checkbox>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item>
        <Row>
          <Col>
            <Button type="primary" htmlType="submit" loading={loading}>
              {submitText}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  )
})
