import React from 'react'
import ReactDOM from 'react-dom'
import {ConfigProvider} from 'antd'
import frFR from 'antd/lib/locale/fr_FR'

import Routes from './routes'
import reportWebVitals from './reportWebVitals'

import './App.less'

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={frFR}>
      <Routes/>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)


reportWebVitals()
