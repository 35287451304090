import Draggable from 'react-draggable'
import React, {memo, useEffect} from 'react'
import {Spin, Row, Col, Input, Collapse} from 'antd'
import {CalendarOutlined, AppstoreOutlined, VideoCameraOutlined} from '@ant-design/icons'

import './MediaList.scss'
import {useFetch} from '../../utils/UseFetchData'
import routesBackend from '../../utils/routeBackend'

const {Panel} = Collapse

const VideoListContainer = memo((props) => {
  const {
    hasFiles = false,
    hasScene = false,
    hasVideos = true,
    hasImages = false,
    hasHorloge = false,
    hasAgendas = false,
    useDraggable = true,
    dropEventForViewPort
    // dropEventForTimeline
  } = props

  const {load: loadHorloges} = useFetch()
  const {loading: loadingFiles, load: loadFiles, items: files} = useFetch()
  const {loading: loadingScene, load: loadScenes, items: scenes} = useFetch()
  const {loading: loadingAgenda, load: loadAgendas, items: agendas} = useFetch()

  const images = files.filter(file => file.mimetype.includes('image'))
  const videos = files.filter(file => file.mimetype.includes('video'))

  useEffect(() => {
    if (hasFiles) loadFiles(routesBackend.FILE.INDEX, 'files')
    if (hasScene) loadScenes(routesBackend.SCENE.INDEX, 'scenes')
    if (hasAgendas) loadAgendas(routesBackend.AGENDA.INDEX, 'agendas')
    if (hasHorloge) loadHorloges(routesBackend.HORLOGE.INDEX, 'horloges')
  }, [hasFiles, hasScene, hasAgendas, hasHorloge, loadFiles, loadScenes, loadAgendas, loadHorloges])

  const onChangeCollapse = key => {
    console.log(key)
  }

  const onInputChange = () => {
  }

  const handleStart = () => {
    // console.log('==================== handleStart =================')
  }

  const handleDrag = (args) => {
    // console.log('==================== handleDrag =================', args)
  }

  const handleStop = (image) => {
    // console.log(image)
    dropEventForViewPort(image)
    var element = document.getElementById('scene-list-container')
    element.style.position = null
  }

  return (
    <div id="external-events" style={{backGroundColor: '#fff'}}>
      <Row>
        <Col span={8}>
          <h2>Média</h2>
        </Col>
        <Col span={16}>
          <Input.Search
            placeholder="Recherche"
            onChange={onInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={[10, 20, 3, 4]} onChange={onChangeCollapse} bordered={false}>
            {hasFiles && loadingFiles ? <SpinerLocal/> : <>
              {hasImages && (
                <Panel header="Images" key="10">
                  <Row>
                    {images.map((image) => (
                      <Col key={image.id} span={8} style={{textAlign: 'center'}}>
                        {useDraggable ? (
                          <Draggable
                            handle=".handle"
                            onStart={handleStart}
                            onDrag={handleDrag}
                            onStop={() => handleStop(image)}
                            position={{x: 0, y: 0}}
                            zIndex={999}
                          >
                            <div id={image.id} className="handle fc-event fc-daygrid-event fc-daygrid-block-event">
                              <img width={50} height={50} src={image.path} alt=""/>
                              <FileTitle>{image.id}-{image.name}</FileTitle>
                            </div>
                          </Draggable>
                        ) : (
                          <div id={`${image.id}-App\\Models\\File`} className="handle fc-event fc-daygrid-event fc-daygrid-block-event"
                               data-color={JSON.stringify({color: 'blue'})}
                               data-content={JSON.stringify({title: image.title, mediable_id: image.id, mediable_type: 'App\\Models\\File'})}>
                            <img width={50} height={50} src={image.path} alt=""/>
                            <FileTitle>{image.id}-{image.name}</FileTitle>
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                </Panel>
              )}
              {hasVideos && (
                <Panel header="Vidéos" key="20">
                  <Row>
                    {videos.map((video) => (
                      <Col key={video.id} span={8} style={{textAlign: 'center'}}>
                        {useDraggable ? (
                          <Draggable
                            handle=".handle"
                            onStart={handleStart}
                            onDrag={handleDrag}
                            onStop={() => handleStop(video)}
                            position={{x: 0, y: 0}}
                            zIndex={999}
                          >
                            <div className="handle fc-event fc-daygrid-event fc-daygrid-block-event"> {/*fc-h-event */}
                              <VideoCameraOutlined/>
                              <FileTitle>{video.id} {video.name}</FileTitle>
                            </div>
                          </Draggable>
                        ) : (
                          <div id={`${video.id}-App\\Models\\File`} className="handle fc-event fc-daygrid-event fc-daygrid-block-event"
                               data-color={JSON.stringify({color: 'green'})}
                               data-content={JSON.stringify({title: video.title, mediable_id: video.id, mediable_type: 'App\\Models\\File'})}>
                            <VideoCameraOutlined/>
                            <FileTitle>{video.id} {video.name}</FileTitle>
                          </div>
                        )}
                      </Col>)
                    )}
                  </Row>
                </Panel>
              )}
            </>}

            {hasAgendas && (loadingAgenda ? <SpinerLocal/> : <Panel header="Agendas" key="3">
              <Row>
                {agendas.map(agenda => (
                  <Col key={agenda.id} span={8} style={{textAlign: 'center'}}>
                    {useDraggable ? (
                      <Draggable
                        handle=".handle"
                        onStart={handleStart}
                        onDrag={handleDrag}
                        onStop={() => handleStop(agenda)}
                        position={{x: 0, y: 0}}
                        zIndex={999}
                      >
                        <div className="handle fc-event fc-daygrid-event fc-daygrid-block-event"> {/*fc-h-event */}
                          <VideoCameraOutlined/>
                          <FileTitle>{agenda.id} {agenda.name}</FileTitle>
                        </div>
                      </Draggable>
                    ) : (
                      <div id={`${agenda.id}-App\\Models\\Agenda`} className="fc-event fc-daygrid-event fc-daygrid-block-event"
                           data-color={JSON.stringify({color: 'red'})}
                           data-content={JSON.stringify({title: agenda.title, mediable_id: agenda.id, mediable_type: 'App\\Models\\Agenda'})}>
                        <CalendarOutlined/>
                        <FileTitle>{agenda.name}</FileTitle>
                      </div>
                    )}
                  </Col>)
                )}
              </Row>
            </Panel>)}
            {hasScene && (loadingScene ? <SpinerLocal/> : <Panel header="Scenes" key="4">
              <Row>
                {scenes.map(scene => <Col key={scene.id} span={8} style={{textAlign: 'center'}}>
                  {useDraggable ? (
                    <Draggable
                      handle=".handle"
                      onStart={handleStart}
                      onDrag={handleDrag}
                      onStop={() => handleStop(scene)}
                      position={{x: 0, y: 0}}
                      zIndex={999}
                    >
                      <div className="handle fc-event fc-daygrid-event fc-daygrid-block-event"> {/*fc-h-event */}
                        <VideoCameraOutlined/>
                        <FileTitle>{scene.id} {scene.name}</FileTitle>
                      </div>
                    </Draggable>
                  ) : (
                    <div id={`${scene.id}-App\\Models\\Scene`} className="fc-event fc-daygrid-event fc-daygrid-block-event"
                         data-content={JSON.stringify({title: scene.title, mediable_id: scene.id, mediable_type: 'App\\Models\\Scene'})}>
                      <AppstoreOutlined/>
                      <FileTitle>{scene.name}</FileTitle>
                    </div>)}
                </Col>)}
              </Row>
            </Panel>)}
          </Collapse>
        </Col>
      </Row>
    </div>
  )
})

const FileTitle = ({children}) => <p style={{fontSize: 10, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{children}</p>
const SpinerLocal = () => <div className="example"><Spin/></div>

export default VideoListContainer
