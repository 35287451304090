import React from 'react'
import {Button} from 'antd'
import {Rnd} from 'react-rnd'
import {ReloadOutlined} from '@ant-design/icons'

export default class SceneEditorTimelineItem extends React.Component {
  shouldComponentUpdate (nextProps) {
    return !(this.props.relation === nextProps.relation
      && this.props.editorDurationWidth === nextProps.editorDurationWidth
      && this.props.scaling === nextProps.scaling
      && this.props.maxZindex === nextProps.maxZindex
      && this.props.itemsRef === nextProps.itemsRef
      && this.props.magnetIsActive === nextProps.magnetIsActive)
  }

  componentWillUpdate (nextProps) {
    this.rnd.updatePosition(
      {
        x: (nextProps.relation.startTimeOffset / nextProps.scaling * nextProps.editorDurationWidth),
        y: nextProps.relation.zIndex * 50
      }
    )
  }

  // componentDidMount () {
  //   console.log('lr media ' + JSON.stringify(this.props.media))
  // }

  magneticPositionsX = {}
  magnetX = -1

  /**
   * Pendant qu'on drag, si magnet est activé, garde en mémoire la dernière position magnetique.
   */
  draging = (event, ui) => {
    if (this.props.magnetIsActive) {
      const keys = Object.keys(this.magneticPositionsX)
      for (let i = 0; i < keys.length; i++) {
        let gapInPos = ui.position.left - keys[i]
        if (gapInPos > -15 && gapInPos < 15) {
          this.magnetX = keys[i]
          break
        } else {
          this.magnetX = -1
        }
      }
    }
    this.props.setCurrentMagnetX(this.magnetX)
  }

  resizing = (direction, styleSize, clientSize) => {
    if (this.props.magnetIsActive) {
      const keys = Object.keys(this.magneticPositionsX)
      for (let i = 0; i < keys.length; i++) {
        let gapInPos = this.rnd.state.x + clientSize.width - keys[i]
        if (gapInPos > -15 && gapInPos < 15) {
          this.magnetX = keys[i]
          break
        } else {
          this.magnetX = -1
        }
      }
    }
    this.props.setCurrentMagnetX(this.magnetX)
  }

  /**
   * Récupère les positions de début et fin des élément dans la timeline.
   * les mets dans un object, les positions sont les clé, les valeurs sont les id des items à ces positions.
   */
  calculateMagneticPosition = () => {
    let newMagneticPositionsX = {}

    Object.keys(this.props.itemsRef).forEach((key) => {
      const relation = this.props.itemsRef[key].props.relation
      if (relation.idRelation === this.props.relation.idRelation)
        return

      let rndStartPosX = Math.round(relation.startTimeOffset / this.props.scaling * this.props.editorDurationWidth)
      let rndStopPosX = rndStartPosX + (Math.max(Math.round(relation.duration / this.props.scaling * this.props.editorDurationWidth), 30))

      if (newMagneticPositionsX[rndStartPosX])
        newMagneticPositionsX[rndStartPosX].push(relation.idRelation)
      else
        newMagneticPositionsX[rndStartPosX] = [relation.idRelation]
      if (newMagneticPositionsX[rndStopPosX])
        newMagneticPositionsX[rndStopPosX].push(relation.idRelation)
      else
        newMagneticPositionsX[rndStopPosX] = [relation.idRelation]
    })

    this.magneticPositionsX = newMagneticPositionsX
  }

  getXFromRelation = relation => Math.round(relation.startTimeOffset / this.props.scaling * this.props.editorDurationWidth)

  getWidthFromRelation = relation => Math.max(Math.round(relation.duration / this.props.scaling * this.props.editorDurationWidth), 30)

  render () {
    const {
      scale,
      media,
      scaling,
      relation,
      isSelected,
      maxZindex,
      magnetIsActive,
      editorDurationWidth
    } = this.props

    const y = relation.zIndex * 50
    const x = this.getXFromRelation(relation)
    const width = this.getWidthFromRelation(relation)

    return (
      <Rnd
        initial={{
          x: x,
          y: y,
          width: width,
          height: 44
        }}
        isResizable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
        ref={(ref) => this.rnd = ref}
        onDragStart={() => this.calculateMagneticPosition()}
        onClick={() => this.props.onClick(relation.idRelation)}
        onResizeStart={() => this.calculateMagneticPosition()}
        resizeGrid={[editorDurationWidth / (scaling / scale), 1]}
        moveGrid={[editorDurationWidth / (scaling / scale), 50]}
        bounds={{left: 0, right: 10000, top: 0, bottom: (maxZindex - 1) * 50}}
        onDragStop={(event, ui) => {
          const newZindex = Math.round((ui.position.top / 150 * 150) / 50)
          let newStart
          if (magnetIsActive && this.magnetX > -1) {
            newStart = Math.round((this.magnetX / editorDurationWidth * scaling) / scale) * scale
            this.rnd.updatePosition({x: this.magnetX, y: newZindex})
          } else {
            newStart = Math.round((ui.position.left / editorDurationWidth * scaling) / scale) * scale
          }
          this.props.updateRelation(relation.idRelation, {startTimeOffset: newStart, zIndex: newZindex})
          this.magnetX = -1
          this.props.setCurrentMagnetX(this.magnetX)
        }}
        onDrag={(event, ui) => this.draging(event, ui)}
        className={isSelected ? 'editor-separation-element selectedItem' : 'editor-separation-element'}
        onResizeStop={(direction, styleSize, clientSize) => {
          let newDuration
          if (magnetIsActive && this.magnetX > -1) {
            newDuration = Math.round(((this.magnetX - x) / editorDurationWidth * scaling) / scale) * scale
            this.rnd.updateSize({width: this.getWidthFromRelation(relation), height: 43})
          } else {
            newDuration = Math.round((clientSize.width / editorDurationWidth * scaling) / scale) * scale
          }
          this.props.updateRelation(relation.idRelation, {duration: newDuration})
          this.magnetX = -1
          this.props.setCurrentMagnetX(this.magnetX)
        }}
        onResize={(direction, styleSize, clientSize) => this.resizing(direction, styleSize, clientSize)}
      >
        <div>
          <ul style={{listStyle: 'none', margin: 0, padding: 0, width: '100%'}}>
            <li>{media.name}</li>
            <li>{relation.startTimeOffset / 1000} : Décalage (s)</li>
            <li>{relation.duration === 0 ? <span>&infin;</span> : relation.duration / 1000}: Durée (s)</li>
          </ul>

          <Button
            size="small"
            shape="circle"
            onClick={() => {
              const initDuration = media.duration === 0 ? 60000 : media.duration
              this.props.updateRelation(relation.idRelation, {duration: initDuration})
            }}
            title="Réinitialiser la durée"
          >
            <ReloadOutlined/>
          </Button>
        </div>
      </Rnd>
    )
  }
}
