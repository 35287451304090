import Cookies from 'js-cookie'
import resource from '../utils/resource'
import routesBackend from '../utils/routeBackend'

const userKey = '@user'
export const tokenKey = '@tokenJwt'

const LoginService = {
  /**
   * Login user
   *
   * @param credentials
   * @returns {Promise<*>}
   */
  attemptLogin: async (credentials: Object) => await resource.post(routesBackend.AUTH.LOGIN, credentials),

  /**
   * Chech if user is authenticate
   *
   * @returns {boolean}
   */
  check: () => !!Cookies.get(tokenKey),

  /**
   * Chech if user is not authenticate
   * @returns {boolean}
   */
  guest: () => Cookies.get(tokenKey) === undefined,

  /**
   * Register user
   * @param data
   * @returns {Promise<*>}
   */
  register: async (data) => await resource.post(routesBackend.AUTH.REGISTER, data),

  /**
   * Register user
   * @param data
   * @returns {Promise<*>}
   */
  update: async (data) => await resource.put(routesBackend.AUTH.UPDATE, data),

  /**
   * Get current user authentecated
   *
   * @returns {Promise<any>}
   */
  getUser () {
    try {
      const user = Cookies.get(userKey)
      if (user) {
        return JSON.parse(user)
      }
    } catch (e) {
      this.logOut()
      this.logOutServer().then(() => {
      })
    }
  },

  /**
   * Set current user authentecated
   *
   * @param user
   */
  setUser: (user) => Cookies.set(userKey, JSON.stringify(user)),

  /**
   * Get current user token jwt
   *
   * @returns {Promise<*>}
   */
  getToken () {
    try {
      const token = Cookies.get(tokenKey)
      if (token) {
        return JSON.parse(token)
      }
    } catch (e) {
      return this.logOut()
    }
  },

  /**
   * Set current user token jwt
   *
   * @returns {Promise<*>}
   */
  setToken (token: string) {
    try {
      Cookies.remove(tokenKey)
      return Cookies.set(tokenKey, JSON.stringify(token))
    } catch (e) {
    }
  },

  /**
   * Logout user local <remove user and token jwt>
   */
  logOut () {
    Cookies.remove(userKey)
    Cookies.remove(tokenKey)
  },

  /**
   * Send request to server for remove token jwt
   *
   * @returns {Promise<*>}
   */
  logOutServer: async () => await resource.post(routesBackend.AUTH.LOGOUT),

  /**
   * Send a request to the server to retrieve the information of the current user
   *
   * @returns {Promise<*>}
   */
  getUserFromApi: async () => await resource.get(routesBackend.AUTH.USER)
}

export default LoginService
