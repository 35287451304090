import React, {memo} from 'react'
import {Select, Space} from 'antd'

const {Option} = Select

const ScreensContent = memo(({onChange, scenes, defaultValue, width = 300}) => {
  return (
    <Space direction="vertical">
      <Select
        showSearch
        onChange={onChange}
        optionFilterProp="children"
        loading={scenes.length <= 0}
        defaultValue={defaultValue || null}
        placeholder="Sélectionner une scène"
        style={{minWidth: '100%', maxWidth: '100%', width}}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {scenes.map(({id, name}) => (
          <Option
            key={id}
            value={id}
          >
            {name}
          </Option>
        ))}
      </Select>
    </Space>
  )
})

export default ScreensContent
