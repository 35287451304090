import React from 'react';
import {Layout} from 'antd';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import { LoginPage } from '../Pages';
import { LoginService } from '../services';
import { SidBarApp, AppHeader } from '../Shared';
import LINKS, { LINKS_PATH } from './helpersRoute';

const {Content} = Layout

export default function Routes () {
  return (
    <Router>
      <Layout style={{minHeight: '100vh'}}>
        <Switch>
          {LoginService.guest() && (
            <Route exact path="/login">
              <LoginPage/>
            </Route>
          )}

          {window.location.pathname !== 'login' && (
            <>
              <AppHeader/>
              <Layout>
                <SidBarApp LINKS_PATH={LINKS_PATH}/>
                <Layout>
                  <Content
                    className="site-layout-background"
                    style={{padding: '20px 40px', margin: 0}}
                  >
                    {Object.keys(LINKS).map((link, key) => (
                      <Route
                        exact
                        key={key}
                        enumerable
                        path={LINKS[link].url}
                      >
                        {LINKS[link].component}
                      </Route>
                    ))}
                  </Content>
                </Layout>
              </Layout>
            </>
          )}
        </Switch>
      </Layout>
    </Router>
  )
}
