import React from 'react'
import {Layout, Input, Button, Row, Col} from 'antd'
import {ArrowDownOutlined} from '@ant-design/icons'

// import {SceneService} from '../../../services'
//import MediaListContainer from '../../../Shared/MediaListContainer'
import VideoListContainer from '../../../Shared/MediaListContainer'
import SceneEditorTimeline from '../../../Shared/SceneEditorContainer/SceneEditorTimeline'
import SceneEditorViewPort from '../../../Shared/SceneEditorContainer/SceneEditorViewPort'
import '../../../Shared/SceneEditorContainer/SceneEditorContainer.scss'

export class index extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mediaInScene: {},
      mediaSelected: undefined,
      mediaEdit: {
        id: -1,
        name: '',
        type: 'scene',
        duration: 60000,
        ratioNumerator: 16,
        ratioDenominator: 9
      },
      isFetching: true,
      scaling: 100000,
      interval: 0,
      mediaControls: 'pause'
      //calendarEdit: {}
    }
  }

  changeScaling = pourcent => {
    this.setState({
      scaling: Math.round((this.state.scaling * pourcent) / 1000) * 1000
    })
  }

  render () {
    let screenSparationsDivs = []

    Object.keys(this.state.mediaInScene).forEach((key) => {
      const media = this.props.mediaById[this.state.mediaInScene[key].id]
      const relation = this.state.mediaInScene[key]

      // Séparation de l'écran
      if ((
        (relation.startTimeOffset <= (this.state.interval)) &&
        (relation.startTimeOffset + relation.duration >= (this.state.interval))) || (relation.duration === 0)
      ) {
        screenSparationsDivs.push(
          <SceneEditorViewPort
            key={key}
            zIndex={relation.zIndex}
            editorHeight={this.editorHeight}
            editorWidth={this.editorWidth}
            ratio={relation.ratio}
            x={relation.boxLeft / 100 * this.editorWidth}
            y={relation.boxTop / 100 * this.editorHeight}
            width={relation.boxWidth / 100 * this.editorWidth}
            height={relation.boxHeight / 100 * this.editorHeight}
            onClick={() => {
              if (this.state.mediaSelected !== key)
                this.selecteMediaInScene(key)
            }}
            onResizeStop={this.onResizeStop}
            onDragStop={this.onDragStop}
            media={media}
            mediaControls={!isNaN(this.state.mediaControls) ? String((this.state.mediaControls - relation.startTimeOffset) % media.duration) : this.state.mediaControls}
            offset={relation.startTimeOffset}
            highlight={this.state.mediaSelected === key}
          />
        )
      }
    })


    return (
      <Layout className="display-management-content-layout">
        <Layout.Sider
          theme="light"
          width="auto"
        >
          {/* <MediaListContainer hasFiles/> */}
          <VideoListContainer hasFiles/>
        </Layout.Sider>


        <Layout.Content id="scene-list-container">
          <Row>
            <Col id="editor-positions" span="24">
              {Object.keys(this.state.mediaInScene).length === 0 ? (
                <div id="drop-empty-container">
                  <ArrowDownOutlined/><br/>
                  <span>Déplacez des médias ici !</span>
                </div>
              ) : (
                <div>
                  {screenSparationsDivs}
                </div>
              )}
            </Col>
          </Row>
          <SceneEditorTimeline
            scene={this.state.mediaEdit}
            relations={this.state.mediaInScene}
            medias={this.props.mediaById}
            scaling={this.state.scaling}
            onChangeScaling={this.onChangeScaling}
            editorDurationWidth={this.editorDurationWidth}
            interval={this.state.interval}
            setSceneInterval={this.setSceneInterval}
            playScene={this.playScene}
            pauseScene={this.pauseScene}
            rewindScene={this.rewindScene}
            changeScaling={this.changeScaling}
            onClick={this.onClickTimeline}
            updateRelation={this.updateRelation}
            highlightSelected={this.state.mediaSelected}
          />
        </Layout.Content>


        <Layout.Sider
          theme="light"
          width={250}
          id="calendar-container"
        >
          <div>
            <Input
              style={{marginRight: '20px'}}
              value={this.state.calendarEdit.name}
              onPressEnter={this.submitChange}
              /*
              onChange={(e) => {
                  this.setState({
                  calendarEdit: {
                      ...this.state.calendarEdit,
                      name: e.target.value
                  }
                  })
              }}
  */
              placeholder="nom de la scène"/>
            <br/>
            <Button
              style={{padding: '5px', display: 'block', margin: '10px auto'}}
              // disabled={this.state.calendarEdit.name === ''}
              onClick={this.submitChange}
              // loading={this.state.isFetching}
              title="Il faut un nom avant de pouvoir sauvegarder"
              type="primary" size="large">Sauvegarder</Button>
            <br/>
            <hr/>
            <p>Cliquez sur un média pour modifier ses informations.</p>
          </div>
        </Layout.Sider>
      </Layout>
    )
  }
}

export default index
