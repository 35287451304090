import React, {memo} from 'react'
import {Menu, Layout} from 'antd'
import {Link} from 'react-router-dom'

import {capitalizeFirstLetter} from '../../../routes/helpersRoute'

const {Sider} = Layout

const SidBarApp = memo(({LINKS_PATH}) => {
  return (
    <Sider
      width={280}
      style={{backgroundColor: '#003366'}}
    >
      <Menu
        theme="dark"
        mode="inline"
        focusable={true}
        defaultSelectedKeys={Object.keys(LINKS_PATH)}
        style={{height: '100%', backgroundColor: '#003366', margin: 0, padding: 0}}
      >
        {Object.keys(LINKS_PATH).map((it, key) => (
          <Menu.Item
            key={key}
            icon={LINKS_PATH[it].icon}
          >
            <Link
              to={LINKS_PATH[it].url}
            >
              {capitalizeFirstLetter(LINKS_PATH[it].title)}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  )
})

export default SidBarApp
