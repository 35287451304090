import Cookies from 'js-cookie'
import resource from '../utils/resource'
import routesBackend from '../utils/routeBackend'

const userKey = '@user'
export const tokenKey = '@tokenJwt'

const ProfileService = {
  /**
   * Get current user authentecated
   *
   * @returns {Promise<any>}
   */
  async getUser () {
    try {
      const user = Cookies.get(userKey)
      if (user) {
        return JSON.parse(user)
      }
    } catch (e) {
      await this.logOut()
      await this.logOutServer()
    }
  },

  /**
   * Set current user authentecated
   *
   * @param user
   */
  setUser: (user: Object) => Cookies.set(userKey, JSON.stringify(user)),

  /**
   * Set current user authentecated
   *
   * @param id
   * @param data
   */
  updateProfile: async (id: Number, data: Object) => await resource.put(`${routesBackend.AUTH.USER}/${id}`, data),

  /**
   * Get current user token jwt
   *
   * @returns {Promise<*>}
   */
  getToken () {
    try {
      const token = Cookies.get(tokenKey)
      if (token) {
        return JSON.parse(token)
      }
    } catch (e) {
      return this.logOut()
    }
  },

  /**
   * Set current user token jwt
   *
   * @returns {Promise<*>}
   */
  setToken (token: string) {
    try {
      Cookies.remove(tokenKey)
      return Cookies.set(tokenKey, JSON.stringify(token))
    } catch (e) {
    }
  },

  /**
   * Logout user local <remove user and token jwt>
   *
   * @returns {Promise<void>}
   */
  logOut () {
    Cookies.remove(userKey)
    Cookies.remove(tokenKey)
  },

  /**
   * Send request to server for remove token jwt
   *
   * @returns {Promise<*>}
   */
  logOutServer: async () => await resource.post(routesBackend.AUTH.LOGOUT),

  /**
   * Send a request to the server to retrieve the information of the current user
   *
   * @returns {Promise<*>}
   */
  getUserFromApi: async () => await resource.get(routesBackend.AUTH.USER)
}

export default ProfileService
