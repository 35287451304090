import React from 'react'
import {Rnd} from 'react-rnd'

export default class SceneEditorTimelineCursor extends React.Component {
  /*
    constructor() {
      super();
      this.state = {
        width: 200,
        height: 200,
        xt: 10,
        y: 10
      };
    }
     */
  componentWillReceiveProps (nextProps) {
    this.rnd.updatePosition({
      x: nextProps.x
    })
  }

  // rnd

  render () {
    return (
      <Rnd
        initial={{
          x: 0,
          y: 0,
          width: 25,
          height: this.props.height
        }}
        zIndex={130}
        moveAxis="x"
        bounds={'parent'}
        isResizable={{
          top: false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
        ref={(c) => this.rnd = c}
        size={{width: '5', height: '100'}}
        className="editor-cursor-element"
        onDragStop={() => this.rnd.updateZIndex(130)}
        moveGrid={[this.props.editorDurationWidth / (this.props.scaling / 100), 1]}
        onDrag={(event, ui) => this.props.onDrag(ui.position.left)}
      />


    )
  }
}
