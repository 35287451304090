import {Input} from 'antd'
import React, {memo} from 'react'

import ScreensContent from './ScreensContent'
import FormHoursScreen from './FormHoursScreen'

const AddScreenContent = memo(props => {
  const {
    scenes,
    addScreen,
    handleAdd,
    textFieldCode,
    textFieldName,
    handleAddScreenCodeChange,
    handleChangeAddScreenName,
    handleAddScreenSceneChange,
    handleAddScreenHourEndChange,
    handleAddScreenHourStartChange
  } = props

  return <div style={{textAlign: 'left'}}>
    <div>
      <h4>
        Code d'identification du <b style={{textTransform: 'uppercase'}}>Raspberry</b> <span style={{color: 'red'}}>*</span>
      </h4>
      <Input
        value={addScreen.code}
        onPressEnter={handleAdd}
        addonBefore={textFieldCode}
        onChange={handleAddScreenCodeChange}
        placeholder="Code d'identification du Raspberry"
      />
    </div>

    <div style={{marginTop: '1.6rem'}}>
      <h4>
        Nom de la <b style={{textTransform: 'uppercase'}}>télévision</b> <span style={{color: 'red'}}>*</span>
      </h4>
      <Input
        value={addScreen.name}
        onPressEnter={handleAdd}
        addonBefore={textFieldName}
        placeholder="Donnez une nom à la télévision"
        onChange={handleChangeAddScreenName}
      />
    </div>

    <div style={{marginTop: '1.6rem'}}>
      <h4>
        Sélectionner une scène <span style={{color: 'red'}}>*</span>
      </h4>
      <ScreensContent
        scenes={scenes}
        onChange={handleAddScreenSceneChange}
      />
    </div>

    <div style={{marginTop: '1.6rem', width: '100%'}}>
      <FormHoursScreen
        defaultValueEndAt={addScreen.hour_end}
        defaultValueStartAt={addScreen.hour_start}
        onChangeEndAt={handleAddScreenHourEndChange}
        onChangeStartAt={handleAddScreenHourStartChange}
      />
    </div>
  </div>
})

export default AddScreenContent
