import resource from '../utils/resource'
import routesBackend from '../utils/routeBackend'

const groupUsersService = {
  /**
   * Get all files for one entity
   *
   * @returns {Promise<*>}
   */
  all: async () => await resource.get(routesBackend.GROUP_USERS.INDEX),
  /**
   * Save files
   *
   * @returns {Promise<*>}
   */
  store: async (data: Object, config = null) => await resource.post(routesBackend.GROUP_USERS.STORE, data, config),
  /**
   * Get one file
   *
   * @returns {Promise<*>}
   */
  show: async (id: Number) => await resource.get(`${routesBackend.GROUP_USERS.SHOW}/${id}`),
  /**
   * Get one file
   *
   * @returns {Promise<*>}
   */
  update: async (id: Number, data: Object) => await resource.put(`${routesBackend.GROUP_USERS.SHOW}/${id}`, data),
  /**
   * Delete a file
   *
   * @returns {Promise<*>}
   */
  destroy: async (id: Number) => await resource.delete(`${routesBackend.GROUP_USERS.SHOW}/${id}`)
}

export default groupUsersService
