import React from 'react'
import PropTypes from 'prop-types'

import './TitleBar.scss'

export default class TitleBar extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element)
  }

  render () {
    const {children, title} = this.props

    return (
      <div className="maw-title-bar">
        {title && <h1 style={{fontSize: '1.2rem'}}>{title}</h1>}
        {children}
        <hr style={{color: '#40A9FF', opacity: 0.16, margin: '0 0 1rem 0'}}/>
      </div>
    )
  }
}
