import React from 'react'
import PropTypes from 'prop-types'
import {Button, Input} from 'antd'
import EditOutlined from '@ant-design/icons/EditOutlined'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import CheckOutlined from '@ant-design/icons/CheckOutlined'

import './EditableCell.scss'

export default class EditableCell extends React.Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)

    this.state = {
      isEditing: false,
      newValue: ''
    }
  }

  /**
   * Active l'édition de la valeur de la cellule.
   */
  startEditing = () => {
    this.setState({
      isEditing: true,
      newValue: this.props.record[this.props.field]
    })
  }

  /**
   * Callback au changement de valeur dans l'input de modification.
   */
  handleEditInputChange = (e) => {
    this.setState({newValue: e.target.value})
  }

  /**
   * Callback à la validation de la modification.
   */
  validateEdit = () => {
    const {newValue} = this.state
    const {field, record} = this.props
    const newRecord = {
      ...record,
      [field]: newValue
    }
    this.props.onEdit(newRecord, field, newValue)
    this.setState({isEditing: false})
  }

  /**
   * Callback à l'annulation de la modification.
   */
  cancelEdit = () => {
    this.setState({isEditing: false})
  }

  render () {
    const {field, record} = this.props
    const {isEditing, newValue} = this.state

    return (
      <div className="maw-editable-cell">
        <div className="button-box">
          {isEditing ? <Button
            size="small"
            shape="circle"
            onClick={this.cancelEdit}
            icon={<CloseOutlined/>}
          /> : <EditOutlined
            onClick={this.startEditing}
            style={{padding: '0 .5rem'}}
          />}

          {isEditing && <Button
            type="primary"
            icon={<CheckOutlined/>}
            size="small"
            shape="circle"
            onClick={this.validateEdit}
            style={{visibility: newValue === record[field] ? 'hidden' : 'visible'}}
          />}
        </div>

        <div className="content">
          {isEditing ? <Input value={newValue} onChange={this.handleEditInputChange} onPressEnter={this.validateEdit}/> : record[field]}
        </div>
      </div>
    )
  }
}
