import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Popconfirm,
  Tooltip
} from 'antd'
import {
  EyeOutlined,
  EditOutlined,
  LockOutlined,
  UnlockTwoTone,
  DeleteOutlined,
  CalendarOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'

const ActionsCellFun = ({permissions, permission, onlock, onUnlock, onScene, onCalendar, onDelete, onEdit, onPreview, onSchedule, record}) => {
  return (
    <div className="maw-actions-cell">
      {onPreview && (
        <Tooltip
          title="Prévisualiser"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            shape="circle"
            type="primary"
            icon={<EyeOutlined/>}
            onClick={() => onPreview(record)}
          />
        </Tooltip>
      )}

      {onEdit && (
        <Tooltip
          title="Modifier"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            ghost
            type="primary"
            shape="circle"
            icon={<EditOutlined/>}
            onClick={() => onEdit(record)}
            disabled={permission && !permission.includes(permissions && permissions.update)}
          />
        </Tooltip>
      )}

      {onSchedule && (
        <Tooltip
          title="Horraires d'activité"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            shape="circle"
            icon={<ClockCircleOutlined/>}
            onClick={() => onSchedule(record)}
          />
        </Tooltip>
      )}

      {onCalendar && (
        <Tooltip
          title="Agenda"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            shape="circle"
            icon={<CalendarOutlined/>}
            onClick={() => onCalendar(record)}
          />
        </Tooltip>
      )}

      {onScene && (
        <Tooltip
          title="Scène"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            shape="circle"
            icon={<CalendarOutlined/>}
            onClick={() => onScene(record)}
          />
        </Tooltip>
      )}

      {onDelete && (
        <Tooltip
          title="Supprimer"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Popconfirm
            okText="Oui"
            cancelText="Non"
            title={() => (<p style={{textAlign: 'center'}}>
              La suppression de ce <br/> média peut entraîner <br/> un dysfonctionnement <br/> sur les agendas qui l'utilisent.<br/>
              Êtes-vous sûr ?
            </p>)}
            onConfirm={() => onDelete(record)}
          >
            <Button
              danger
              shape="circle"
              type="primary"
              icon={<DeleteOutlined/>}
              loading={record.isDeleting}
              disabled={permission && !permission.includes(permissions && permissions.delete)}
            />
          </Popconfirm>
        </Tooltip>
      )}

      {record.state && [record.state].includes('locked') && onUnlock && (
        <Tooltip
          title="Débloquer"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Popconfirm
            okText="Oui"
            cancelText="Non"
            title="Voulez-vous vraiment débloquer cet utilisateur ?"
            onConfirm={() => onUnlock(record)}
          >
            <Button
              shape="circle"
              loading={record.isUnlocked}
              icon={<UnlockTwoTone twoToneColor="#EF4444"/>}
            />
          </Popconfirm>
        </Tooltip>
      )}

      {record.state && ([record.state].includes('unlocked') || [record.state].includes('created')) && onlock && (
        <Tooltip
          title="Bloquer"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Popconfirm
            okText="Oui"
            cancelText="Non"
            onConfirm={() => onlock(record)}
            title="Voulez-vous vraiment bloquer cet utilisateur ?"
          >
            <Button
              shape="circle"
              loading={record.islocked}
              icon={<LockOutlined twoToneColor="#10B981"/>}
            />
          </Popconfirm>
        </Tooltip>
      )}
    </div>
  )
}

ActionsCellFun.props = {
  onEdit: PropTypes.func,
  onlock: PropTypes.func,
  onScene: PropTypes.func,
  onDelete: PropTypes.func,
  onUnlock: PropTypes.func,
  onPreview: PropTypes.func,
  onSchedule: PropTypes.func,
  onCalendar: PropTypes.func,
  record: PropTypes.object.isRequired
}

export default ActionsCellFun
