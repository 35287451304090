import React from 'react'

import {FileService} from '../../services'
import FileTable from '../../Shared/FileTable'
import {openMessage} from '../../Shared/Helper'

export default class FileTableContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      files: [],
      file: null,
      loading: true,
      permission: [],
      selectedRowKeys: []
    }
  }

  componentDidMount () {
    (async () => await this.handleGetFiles())()
  }

  handleGetFiles = async () => {
    await FileService.all().then(({data}) => {
      this.setState({
        files: data.files,
        permission: data.permission
      })
    }).finally(() => this.setState({loading: false}))
  }

  onDelete = async file => {
    await this.deleteFileById(file.id).then(async () => {
      await this.onRefresh()
    })

    await openMessage({content: 'suppression', contentAfter: 'Suppression effectuée avec succès.'})
  }

  deleteFileById = async (id) => {
    return await FileService.destroy(id)
  }

  onDeleteSelection = async () => {
    const {selectedRowKeys} = this.state

    if (selectedRowKeys.length > 0) {
      this.setState({loading: true}, async () => {
        this.state.selectedRowKeys.forEach(fileId => {
          (async () => await this.deleteFileById(fileId))()
        })

        await openMessage({content: 'Suppression en cours ...', contentAfter: 'Suppression effectuée avec succès.'})
        await this.onRefresh()
      })
    } else {
      await openMessage({content: 'Verification en cours ...', contentAfter: 'Il n\'a rien à supprimer. Ajoutez une sélection..', type: 'warning'})
    }
  }

  onNameEdit = async ({id, name}) => {
    this.setState({loading: true})
    await FileService.update(id, {name: name}).then(async () => {
      await openMessage({content: 'Mise à jour ...', contentAfter: 'La mise à jour est un succès.'})
      await this.onRefresh()
    })
      .catch(async (error) => {
        if (error.response.status === 422) {
          await openMessage({
            content: 'Echec de mise à jour ...',
            contentAfter: error.response.data.errors.name.join(' '),
            type: 'error'
          })
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({loading: false})
        }, 200)
      })
  }

  onRefresh = async () => {
    this.setState({loading: true})
    await this.handleGetFiles().finally(() => {
      this.setState({loading: false})
    })
  }

  onSelectChange = selectedRowKeys => this.setState({selectedRowKeys})

  handleLoading = bool => this.setState({loading: bool})

  render () {
    const {files, file, selectedRowKeys, loading, permission} = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <FileTable
        file={file}
        size="small"
        loading={loading}
        dataSource={files}
        permission={permission}
        onDelete={this.onDelete}
        onRefresh={this.onRefresh}
        rowSelection={rowSelection}
        onNameEdit={this.onNameEdit}
        handleLoading={this.handleLoading}
        permissions={{
          create: 'create_file',
          update: 'update_file',
          delete: 'delete_file'
        }}
        onDeleteSelection={this.onDeleteSelection}
      />
    )
  }
}
