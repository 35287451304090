import React from 'react'
import PropTypes from 'prop-types'
// import 'javascripts/utils/clock-js/css/clock-js.css'
// import {clockJs} from 'javascripts/utils/clock-js/js/clock-js'
// import {news20MinJs} from 'javascripts/utils/news-20min-js/js/news-20min-js'

export class FileViewer extends React.Component {
  static propTypes = {
    displayControls: PropTypes.bool,
    file: PropTypes.object,
    height: PropTypes.string,
    videoControls: PropTypes.string,
    width: PropTypes.string
  }

  constructor (props) {
    super(props)

    this.state = {
      mod: 'null',
      viewer: <div style={{backgroundColor: 'black', width: '100%', height: '100%'}}>
        viewer n'est pas displonible pour ce type de fichier.
      </div>,
      containerClock: null,
      containerMeteo: null,
      containerNews: null
    }
  }

  /**
   * @deprecated
   * @see componentDidMount
   */
  notComponentDidMount () {
    const {mimetype, path} = this.props.file
    // const fileUrl = Config.API + 'modules-static-files/Screens/tenants/1/original/' + id

    // console.log(id, mimetype, type, path)

    const sizes = {
      width: this.props.width ? this.props.width : '380px',
      height: this.props.height ? this.props.height : 'auto'
    }

    if (mimetype) {
      // if (mimetype.search('image') === 0) {
      if (mimetype === '') {
        this.setState({
          mod: `image`,
          viewer: <img {...sizes} src={path} alt={''}/>
        })
      } else if (mimetype.search('clock') === 0) {
        this.setState({
          mod: `clock`,
          viewer: <div ref={(r) => this.containerClock = r} style={{width: '100%', height: '100%'}}/>
        }, () => {
          //clockJs(containerClock)
        })
      } else if (mimetype.search('meteo') === 0) {
        this.setState({
          mod: `meteo`,
          viewer: <div ref={(r) => this.containerMeteo = r} style={{width: '100%', height: '100%'}}/>
        }, () => {
          // meteoJs(containerMeteo, 'geneve', 0)
        })
      } else if (mimetype.search('news') === 0) {
        this.setState({
          mod: `news`,
          viewer: <div style={{width: '100%', height: '100%', position: 'relative'}}>
            <div ref={(r) => this.containerNews = r} style={{width: '100%', height: '100%'}}/>
            <div style={{top: '0', left: '0', width: '100%', height: '100%', position: 'absolute'}}/>
          </div>
        }, () => {
          //news20MinJs(containerNews, 'de')
        })
      } else if (mimetype.search('video') === 0) {
        if (this.props.displayControls)
          this.setState({
            mod: 'video',
            viewer: <video {...sizes} ref={r => this.videoPlayer = r} src={path} controls/>
          })
        else
          this.setState({
            mod: 'video',
            viewer: <video {...sizes} ref={r => this.videoPlayer = r} src={path} loop muted preload/>
          })
      }
    }
  }

  componentDidMount () {
    const {mimetype, path} = this.props.file

    const sizes = {
      width: this.props.width ? this.props.width : '380px',
      height: this.props.height ? this.props.height : 'auto'
    }

    if (mimetype) {
      // console.log('mintype called')
      // if (mimetype.search('image') === 0) {
      if (mimetype.includes('image')) {
        this.setState({
          mod: `image`,
          viewer: <img {...sizes} src={path} alt={''}/>
        })
        // console.log('is image', this.state)
      } else if (mimetype.includes('clock')) {
        this.setState({
          mod: `clock`,
          viewer: <div ref={(r) => this.containerClock = r} style={{width: '100%', height: '100%'}}/>
        }, () => {
          //clockJs(containerClock)
        })
      } else if (mimetype.includes('meteo')) {
        this.setState({
          mod: `meteo`,
          viewer: <div ref={(r) => this.containerMeteo = r} style={{width: '100%', height: '100%'}}/>
        }, () => {
          // meteoJs(containerMeteo, 'geneve', 0)
        })
      } else if (mimetype.includes('news')) {
        this.setState({
          mod: `news`,
          viewer: <div style={{width: '100%', height: '100%', position: 'relative'}}>
            <div ref={(r) => this.containerNews = r} style={{width: '100%', height: '100%'}}/>
            <div style={{top: '0', left: '0', width: '100%', height: '100%', position: 'absolute'}}/>
          </div>
        }, () => {
          //news20MinJs(containerNews, 'de')
        })
      } else if (mimetype.includes('video')) {
        if (this.props.displayControls)
          this.setState({
            mod: 'video',
            viewer: <video {...sizes} ref={(r) => this.videoPlayer = r} src={path} controls/>
          })
        else
          this.setState({
            mod: 'video',
            viewer: <video {...sizes} ref={(r) => this.videoPlayer = r} src={path} loop muted preload/>
          })
      }
    }
  }

  componentDidUpdate () {
    const {mod} = this.state
    const {videoControls} = this.props

    if (mod === 'video')
      if (videoControls === 'play' && this.videoPlayer.paused) {
        this.videoPlayer.play()
      } else if (videoControls === 'pause' && !this.videoPlayer.paused)
        this.videoPlayer.pause()
      else if (!isNaN(videoControls)) { //si controls est un nombre
        this.videoPlayer.currentTime = parseInt(videoControls) / 1000
      }
    // this.componentDidMount()
  }

  render () {
    return (this.state.viewer)
  }
}

export const StreamFileViewer = ({file}) => {
  const showVideo = () => {
    return (
      file && file.mimetype.includes('video') && <video {...{width: '380px', height: 'auto'}} src={file.path} controls/>
    )
  }

  return <div>
    {file.mimetype.includes('image') && <img src={file.path} width="575px" height="400px" alt=""/>}
    {showVideo()}
  </div>
}
