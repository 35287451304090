import React from 'react'
import {Col, Row, Tooltip} from 'antd'
import LaptopOutlined from '@ant-design/icons/LaptopOutlined'

import TitleBar from '../TitleBar'
import { EditableCell } from '../DataTable'
import MediaTableContainer from '../MediaTable'
import './ScreenTable.scss'

export default class ScreenTable extends React.Component {
  static ColumnModel = {
    status: {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: (a, b) => b.status - a.status,
      className: 'screen-status',
      render: status => {
        let option = {
          status: 'stoped',
          twoToneColor: '#eb2f96',
          title: 'Écran déconnecté',
          classsName: 'ant-alert-error'
        }

        if (status === 'started') {
          option = {
            ...option,
            status: 'started',
            title: 'Écran connecté',
            twoToneColor: '#52c41a',
            classsName: 'ant-alert-success'
          }
        } else if (status === 'ready') {
          option = {
            ...option,
            title: 'Écran en suspens',
            status: 'ready',
            twoToneColor: 'orange',
            classsName: 'ant-alert-warning'
          }
        }

        return (
          <Tooltip title={option.title} mouseEnterDelay={0.5}>
            <LaptopOutlined
              className={option.classsName}
              twoToneColor={option.twoToneColor}
              style={{padding: '.3rem .5rem', borderRadius: '15%', color: 'inherit'}}
            />
          </Tooltip>
        )
      }
    },
    code: (onEdit = null) => {
      const render = onEdit ? (text, screen) => <EditableCell
        field="code"
        record={screen}
        onEdit={onEdit}
      /> : (text, screen) => screen.code

      return {
        title: 'Code',
        key: 'code',
        render
      }
    }
  }

  render () {
    const columns = [
      MediaTableContainer.ColumnModel.name(this.props.onNameEdit),
      ScreenTable.ColumnModel.code(this.props.onCodeEdit),
      ScreenTable.ColumnModel.status,
      MediaTableContainer.ColumnModel.updatedAt
    ]

    return (
      <Row>
        <Col span={24}>
          {/* Title page */}
          <TitleBar title="Écrans"/>

          <MediaTableContainer
            {...this.props}
            columns={columns}
            mediaType="screen"
            dataSource={this.props.dataSource}
          />
        </Col>
      </Row>
    )
  }
}
