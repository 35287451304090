import axios from 'axios'

// Add interceptor of axios
import '../plugins/axios'

const resource = {
  get: async (url: string, config = null) => await axios.get(url, config),
  post: async (url: string, data: any, config = null) => await axios.post(url, data, config),
  put: async (url, data, config = null) => await axios.put(url, data, config),
  patch: async (url, data, config = null) => await axios.patch(url, data, config),
  delete: async (url, config = null) => await axios.delete(url, config)
}

export default resource
