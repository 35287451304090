import {Dropdown, Menu} from 'antd'
import {Link, useHistory} from 'react-router-dom'
import DownOutlined from '@ant-design/icons/DownOutlined'
import React, {useCallback, useEffect, useState} from 'react'

import {openMessage} from '../../Helper'
import {LoginService} from '../../../services'
import { LINKS_PATH } from '../../../routes/helpersRoute'

export default function AppHeader () {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const [user, setUser] = useState({
    id: null,
    type: null,
    state: null,
    email: null,
    name: null,
    phone: null,
    entity_id: null,
    permissions: [],
    last_name: null,
    first_name: null,
    created_at: null,
    updated_at: null,
    entity_name: null
  })

  const logOut = () => {
    history.push('/login')
  }

  const handleLogout = () => {
    setLoading(true)
    LoginService.logOutServer().then(async () => {
      LoginService.logOut()
      openMessage({content: 'Déconnexion en cours', contentAfter: 'Déconnecté'}).then(() => {
        setLoading(false)
        logOut()
        setTimeout(() => {
          window.location.reload()
        }, 200);
      })
    })
  }

  const pathImage = () => `${window.location.origin}/logosignage.png`

  const userInfo = useCallback(() => {
    LoginService.getUserFromApi().then(({data}) => {
      setUser(data)
    })
  }, [setUser])

  useEffect(() => {
    userInfo()
  }, [userInfo])

  const menu = (
    <Menu>
      <Menu.Item key={0}>
        <Link
          title="Profil"
          to={LINKS_PATH.PROFILE.url}
        >
          Profil
        </Link>
      </Menu.Item>
      <Menu.Item key={1}>
        <a
          title="Déconnexion"
          aria-disabled={loading}
          href={LINKS_PATH.FILE.url}
          onClick={e => {
            e.preventDefault()
            handleLogout()
          }}
          className="ant-dropdown-link"
        >
          Déconnexion
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <div
      style={{
        display: 'flex',
        height: '70px',
        widh: '100%',
        alignItems: 'center',
        backgroundColor: '#fff'
      }}
    >
      <div
        style={{
          height: '70px',
          minWidth: '280px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#002447'
        }}
      >
        <Link to={'/files'}>
          <img
            height="auto"
            width={150}
            alt="logosignage"
            src={pathImage()}
          />
        </Link>
      </div>

      <div
        style={{
          width: '100%',
          height: '70px',
          display: 'flex',
          padding: '0 40px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{ textTransform: 'uppercase' }}
        >
          <b>{user.entity_name}</b>
        </div>

        <div>
          <Dropdown
            overlay={menu}
            placement="bottomRight"
          >
            <a
              href="/"
              style={{fontWeight: 'bold'}}
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
            >
              {user.name} <DownOutlined/>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
