import React, {memo} from 'react'
import {Col, Row, TimePicker} from 'antd'

const FormHoursScreen = memo(props => {
  const format = 'HH:mm'
  const {
    onChangeEndAt,
    onChangeStartAt,
    defaultValueEndAt,
    defaultValueStartAt,
    labelEndAt = 'Heure d\'extinction',
    labelStartAt = 'Heure de mise en route'
  } = props

  const style = (key, value) => ({[key]: value})

  return (
    <Row>
      <Col span={12}>
        <div style={style('paddingRight', '6px')}>
          <h4>
            {labelStartAt} <span style={{color: 'red'}}>*</span>
          </h4>
          <TimePicker
            format={format}
            style={{width: '100%'}}
            onChange={onChangeStartAt}
            defaultValue={defaultValueStartAt}
          />
        </div>
      </Col>
      <Col span={12}>
        <div style={style('paddingLeft', '6px')}>
          <h4>
            {labelEndAt} <span style={{color: 'red'}}>*</span>
          </h4>
          <TimePicker
            format={format}
            style={{width: '100%'}}
            onChange={onChangeEndAt}
            defaultValue={defaultValueEndAt}
          />
        </div>
      </Col>
    </Row>
  )
})

export default FormHoursScreen
