import resource from '../utils/resource'
import routesBackend from '../utils/routeBackend'

const SceneService = {
  /**
   * Get all scenes for one entity
   *
   * @returns {Promise<*>}
   */
  all: async () => await resource.get(routesBackend.SCENE.INDEX),

  destroy: async (id) => await resource.delete(`${routesBackend.SCENE.DESTROY}/${id}`),

  update: async (id, data) => await resource.put(`${routesBackend.SCENE.UPDATE}/${id}`, data),

  store: async (data, config = null) => await resource.post(routesBackend.SCENE.STORE, data, config),

  show: async (id) => await resource.get(`${routesBackend.SCENE.SHOW}/${id}`),

}

export default SceneService
