import React from 'react'
import {withRouter} from 'react-router'

import {SceneService} from '../../services'
import {openMessage} from '../../Shared/Helper'
import SceneTable from '../../Shared/SceneTable/SceneTable'

class SceneTableContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      scenes: [],
      loading: true,
      permission: [],
      selectedRowKeys: []
    }
  }

  componentDidMount () {
    (async () => this.handleGetScenes())()
  }

  handleGetScenes = async () => {
    const {data} = await SceneService.all().finally(() => this.setState({loading: false}))
    this.setState({
      scenes: data.scenes,
      permission: data.permission
    })
  }

  onRefresh = async () => {
    this.setState({loading: true})
    await this.handleGetScenes()
  }

  onAdd = () => this.props.history.push(`/scenes/new`)

  onEdit = scene => this.props.history.push(`/scenes/${scene.id}`, {idAgenda: scene.id})

  onNameEdit = async ({id, name}) => {
    await SceneService.update(id, {name: name})
      .then(async () => {
        await openMessage({content: 'Mise à jour ...', contentAfter: 'La mise à jour est un succès.'})
        await this.onRefresh()
      }).catch(async () => {
        await openMessage({content: 'Mise à jour ...', contentAfter: 'La mise à jour n\'a pas marché.', type: 'error'})
      })
  }

  onDelete = async scene => {
    await this.deleteSceneById(scene.id).then(async () => {
      await this.onRefresh()
    })

    await openMessage({content: 'suppression', contentAfter: 'Suppression effectuée avec succès.'})
  }

  deleteSceneById = async (id) => await SceneService.destroy(id)

  onDeleteSelection = async () => {
    this.state.selectedRowKeys.forEach(sceneId => {
      (async () => await this.deleteSceneById(sceneId))()
    })

    await this.onRefresh()
    await openMessage({content: 'Suppression ...', contentAfter: 'Suppression effectuée avec succès.'})
  }

  onSelectChange = selectedRowKeys => this.setState({selectedRowKeys})

  render () {
    const {permission, scenes, loading, selectedRowKeys} = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <SceneTable
        loading={loading}
        onEdit={this.onEdit}
        dataSource={scenes}
        onDelete={this.onDelete}
        permission={permission}
        permissions={{
          create: 'create_scene',
          update: 'update_scene',
          delete: 'delete_scene'
        }}
        onRefresh={this.onRefresh}
        rowSelection={rowSelection}
        onNameEdit={this.onNameEdit}
        onDeleteSelection={this.onDeleteSelection}
      />
    )
  }
}

export default withRouter(SceneTableContainer)
