import {useCallback, useState} from 'react'
import {AxiosService} from '../services'

export function useFetch () {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  const load = useCallback(async (url, key = null) => {
    setLoading(true)

    await AxiosService.get(url)
      .then(({data}) => setItems(
        key ? data[key] : data
      ))
      .finally(() => setLoading(false))
  }, [])

  const postData = useCallback(async (url, data, config) => {
    setLoading(true)
    return await AxiosService.post(url, data, config).finally(() => setLoading(false))
  }, [])

  const putData = useCallback(async (id, url, data, config) => {
    setLoading(true)
    return await AxiosService.put(id, url, data, config).finally(() => setLoading(false))
  }, [])

  return {
    load,
    items,
    loading,
    putData,
    postData
  }
}
