import $ from 'jquery'
import React from 'react'
import PropTypes from 'prop-types'
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined'

import DataTable from '../DataTable'
import EditableCell from '../DataTable/EditableCell/EditableCell'

export default class MediaTable extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    onNameEdit: PropTypes.func,
    onSearch: PropTypes.func,
    displayManagement: PropTypes.object
  }

  /***
   * Collection de modèle de colonne représentant les champs des media.
   * Un modèle peu être un objet définissant la colonne ou une fonction factory
   * qui construit l'objet en fonction de certains paramètres.
   */
  static ColumnModel = {
    // Modèle pour le champ "id"
    id: {
      key: 'id',
      dataIndex: 'id',
      sorter: (a, b) => b.id - a.id,
      title: <input onClick={this.selectAll} id="selectall" type="checkbox"/>,
      render: (text, media) => <input className="ads_Checkbox" value={media.id} type="checkbox"/>
    },
    // Modèle pour le champ "name". La factory prend en paramètre un callback si
    // l'on souhaite que le champ soit modifiable.
    name: (onEdit = null) => {
      const render = onEdit ? (text, media) => <EditableCell record={media} field="name" onEdit={onEdit}/> : (text, media) => media.name

      return {
        title: 'Nom',
        dataIndex: 'name',
        key: 'name',
        searchable: true,
        sorter: (a, b) => b.name.localeCompare(a.name, undefined, {sensitivity: 'accent'}),
        render
      }
    },
    // Modèle pour le champ "ratio"
    ratio: {
      title: 'Ratio',
      key: 'ratio',
      render: (text, media) => `${media.ratio_numerator}/${media.ratio_denominator}`
    },
    // Modèle pour le champ "duration"
    duration: {
      title: 'Durée',
      key: 'duration',
      sorter: (a, b) => b.duration - a.duration,
      render: (text, media) => {
        //return text
        if (!media.duration) {
          return '-'
        }

        const durationinSec = Math.floor(media.duration / 1000)
        const durationInMin = Math.floor(durationinSec / 60)
        const durationInHour = Math.floor(durationInMin / 60)
        const segments = {h: durationInHour, m: durationInMin % 60, s: durationinSec % 60}

        return (segments.h ? segments.h + 'h ' : '')
          + (segments.m || segments.h ? segments.m + 'm ' : '')
          + segments.s + 's'
      }
    },
    // Modèle pour le champ "duration"
    format: {
      title: 'Format',
      key: 'format',
      sorter: (a, b) => b.format - a.format,
      render: (text, media) => media.format
    },
    // Modèle pour le champ "createdAt"
    createdAt: {
      title: 'Date de création',
      dataIndex: 'created_at',
      key: 'created_at',
      // sorter: (a, b) => b.created_at - a.created_at,
      sorter: (a, b) => b.name.localeCompare(a.name, undefined, {sensitivity: 'accent'}),
      render: (text, media) => media.created_at
    },
    // Modèle pour le champ "updatedAt"
    updatedAt: {
      title: 'Dernière mise à jour',
      dataIndex: 'updated_at',
      key: 'updated_at',
      //sorter: (a, b) => b.updated_at - a.updated_at,
      sorter: (a, b) => b.name.localeCompare(a.name, undefined, {sensitivity: 'accent'}),
      render: (text, media) => media.updated_at //new Date().toLocaleString('fr')
    },
    // Modèle pour le champ "Image"
    image: () => {
      const render = (text, media) => {
        if (media.mimetype.includes('image')) {
          return <img src={media.path} width={50} height={50} style={{borderRadius: '100%'}} alt=""/>
        } else if (media.mimetype.includes('image')) {
          return 'Vidéo'
        }

        return <VideoCameraOutlined
          title="Vidéo"
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, height: 50}}
        />
      }

      return {
        title: 'Fichier',
        dataIndex: 'image',
        key: 'image',
        searchable: true,
        // sorter: (a, b) => b.mimetype - a.mimetype,
        render
      }
    }
  }

  /***
   * Retourne les champs dont la valeur peut être recherchée.
   */
  static getSearchableFields (columns) {
    return columns.map((column) => {
      if (column.searchable) {
        return column.dataIndex
      }
      return false
    }).filter((val) => !!val)
  }

  /**
   * Constructeur.
   */
  constructor (props) {
    super(props)

    this.state = {
      searchText: '',
      filterTable: null,
      searchableFields: MediaTable.getSearchableFields(props.columns)
    }
    this.selectAll = this.selectAll.bind(this)
  }

  componentDidMount () {
    $('#selectall').click(function () {
      if (!$(this).is(':checked')) {
        $('.ads_Checkbox').prop('checked', false)
      } else {
        $('.ads_Checkbox').prop('checked', true)
      }
    })

  }

  selectAll = () => {
    alert('seck')
    $('.ads_Checkbox').prop('checked', true)
  }

  // Replace temp the hook componentWillReceiveProps
  componentDidUpdate (nextProps) {
    // Si les collonnes changent, cherche à nouveau les champs "searchable"
    if (nextProps.columns !== this.props.columns) {
      this.setState({
        searchableFields: MediaTable.getSearchableFields(
          nextProps.columns
        )
      })
    }
  }

  // componentWillReceiveProps (nextProps) {
  // Si les collonnes changent, cherche à nouveau les champs "searchable"
  // if (nextProps.columns !== this.props.columns) {
  //   this.setState({
  //     searchableFields: MediaTable.getSearchableFields(nextProps.columns)
  //   })
  // }
  // }

  /**
   * Extrait et normalise les données du store.
   * Retourne un tableau de média, chacun ayant une valeur suplémentaire "isDeleting".
   */
  extractData () {
    const {mediaById, [this.props.mediaType]: {items}, isDeleting} = this.props.displayManagement

    return items.map(id => {
      return {
        ...mediaById[id],
        isDeleting: isDeleting[id]
      }
    })
  }

  /**
   * Récupère les données du store devant être affichée. Cette méthode fait appel à `extractData` et `searchData`.
   */
  getShownData () {
    return this.searchData(this.extractData())
  }

  onSearch = text => {
    this.setState({searchText: text})

    const filterTable = this.props.dataSource.filter(
      o => Object.keys(o).some(
        k => String(o[k]).toLowerCase().includes(text.toLowerCase())
      )
    )

    this.setState({filterTable})
    // this.getShownData();
  }

  /**
   **
   * Recherche dans les données passées en paramètre les enregistrements dont les champs
   * "searchable" correspondent au texte de recherche.
   */
  searchData (data) {
    if (!this.state.searchText) {
      return data
    }

    const reg = new RegExp(this.state.searchText.replace(/ /g, '|'), 'gi')

    return data.map((media) => {
      let match = false
      for (let field of this.state.searchableFields) {
        match |= !!media[field].match(reg)
        if (match) {
          return media
        }
      }
      return false
    }).filter((record) => !!record)
  }

  render () {
    const {
      onAdd,
      onEdit,
      loading,
      columns,
      onDelete,
      onRefresh,
      dataSource,
      rowSelection,
      onDeleteSelection,
      ...otherProps
    } = this.props
    const {filterTable} = this.state

    return (
      <DataTable
        {...otherProps}
        onEdit={onEdit}
        onAdd={onAdd}
        loading={loading}
        columns={columns}
        onDelete={onDelete}
        onRefresh={onRefresh}
        onSearch={this.onSearch}
        rowSelection={rowSelection}
        onDeleteSelection={onDeleteSelection}
        dataSource={filterTable == null ? dataSource : filterTable}
      />
    )
  }
}
