import React from 'react'
import {withRouter} from 'react-router'
import {Layout, Button, Input, Row, Col} from 'antd'
import {ArrowDownOutlined, DeleteOutlined} from '@ant-design/icons'

import $ from 'jquery'
import {SceneService} from '../../../services'
import {sceneJsonParse, openMessage} from '../../../Shared/Helper'
import MediaListContainer from '../../../Shared/MediaListContainer'
// import SceneEditorForm from '../../../Shared/SceneEditorContainer/SceneEditorForm'
import SceneEditorTimeline from '../../../Shared/SceneEditorContainer/SceneEditorTimeline'
import SceneEditorViewPort from '../../../Shared/SceneEditorContainer/SceneEditorViewPort'
// import SceneEditorFormSaveVideo from '../../../Shared/SceneEditorContainer/SceneEditorFormSaveVideo'

class SceneEditorContainer extends React.Component {
  constructor (props) {
    super(props)
    /*  this.state = {
       calendarEdit: {}
     } */
    this.state = {
      interval: 0,
      isFetching: true,
      scaling: 100000,
      scene: {
        id: null,
        duration: null,
        name: null,
        created_at: null,
        ratio_denominator: null,
        ratio_numerator: null,
        updated_at: null,
      },
      actualmedia: null,
      mediaInScene: {},
      mediaControls: 'pause',
      mediaEdit: {
        id: -1,
        name: '',
        type: 'scene',
        duration: 60000,
        ratioNumerator: 16,
        ratioDenominator: 9
      },
      mediaSelected: undefined,
      scene_id: this.props.match.params.id
    }
  }

  componentDidMount () {
    this.loadScene()
  }

  loadScene = () => {
    SceneService.show(this.state.scene_id).then(({ data }) => {
      this.setState({
        scene: data,
        isFetching: false,
        mediaInScene: sceneJsonParse(data.files)
      })
    })
  }

  // componentWillReceiveProps (nextProps) {
  //   //  alert("receive props");
  //   if (nextProps.mediaById) {
  //     if (nextProps.mediaById[this.state.mediaEdit.id]) {
  //       this.setState({
  //         mediaEdit: {
  //           ...this.state.mediaEdit,
  //           name: nextProps.mediaById[this.state.mediaEdit.id].name,
  //           duration: nextProps.mediaById[this.state.mediaEdit.id].duration
  //         }
  //       })
  //     }
  //   }
  //   if (nextProps.relationsById) {
  //     let mediaInScene = {}
  //     let maxScale = this.state.scaling
  //     for (let index in nextProps.relationsById) {
  //       const relation = nextProps.relationsById[index]
  //       if (relation.hostMediaId === this.state.mediaEdit.id) {
  //         const startTimeOffset = relation.startTimeOffset
  //         const duration = relation.duration ? relation.duration : 0
  //         const end = startTimeOffset + duration
  //         if (end > maxScale)
  //           maxScale = end

  //         mediaInScene[relation.id] = {
  //           id: relation.guestMediaId,
  //           boxLeft: relation.boxLeft,
  //           boxTop: relation.boxTop,
  //           boxWidth: relation.boxWidth,
  //           boxHeight: relation.boxHeight,
  //           guestLeft: relation.guestLeft,
  //           guestTop: relation.guestTop,
  //           guestWidth: relation.guestWidth,
  //           guestHeight: relation.guestHeight,
  //           startTimeOffset: startTimeOffset,
  //           duration: duration,
  //           zIndex: relation.zIndex,
  //           idRelation: relation.id, // Id  relation déjà existante
  //           ratio: true
  //         }
  //       }
  //     }
  //     this.setState({
  //       mediaInScene: mediaInScene,
  //       isFetching: false,
  //       scaling: maxScale
  //     })
  //   }
  // }

  componentDidUpdate (prevProps, prevState) {
    this.listDrop()
    this.editorHeight = document.getElementById('editor-positions').offsetHeight
    this.editorWidth = document.getElementById('editor-positions').offsetWidth
    this.editorDurationWidth = document.getElementById('editor-duration-menu').offsetWidth

    //Met à jour la durée de la scène
    const currentDuration = this.calculateCurrentDuration()
    if (prevState.mediaEdit.duration !== currentDuration) {
      this.setState({
        mediaEdit: {
          ...this.state.mediaEdit,
          duration: currentDuration
        }
      })
    }

  }

  mediaDeleted = []
  editorHeight = 0
  editorWidth = 0
  editorDurationWidth = 0
  haveDrag = []
  tmpIdForNewRelation = -1

  timer
  startTime = 0

  calculateCurrentDuration () {
    let duration = 0
    Object.keys(this.state.mediaInScene).forEach((key) => {
      const relation = this.state.mediaInScene[key]
      if ((relation.duration + relation.startTimeOffset) > duration) {
        duration = relation.duration + relation.startTimeOffset
      }
    })
    return duration
  }

  dropEventForViewPort = (image) => {
    // alert("moine "+image);
    const duration = image.duration ? image.duration : 60000
    const zIndex = this.state.mediaInScene.length > 0 ? Math.max(...this.state.mediaInScene.map((o) => o.zIndex.value)) + 1 : 0
    this.setState({
      mediaInScene: {
        ...this.state.mediaInScene,
        [this.tmpIdForNewRelation]: {
          id: image.id,
          media: image,
          boxLeft: 0,
          boxTop: 0,
          boxWidth: 100,
          boxHeight: 100,
          guestLeft: 0,
          guestTop: 0,
          guestWidth: 100,
          guestHeight: 100,
          startTimeOffset: 0,
          duration: duration,
          zIndex: zIndex,
          idRelation: this.tmpIdForNewRelation, // Id le relation déjà existante (négative si aucune)
          ratio: true
        }
      },
      scaling: duration > this.state.scaling ? duration : this.state.scaling
    }, () => this.tmpIdForNewRelation -= 1)
    // alert("nb media "+Object.keys(this.state.mediaInScene).length);
  }

  dropEventForTimeline = (image) => {
    const duration = image.duration ? image.duration : 60000
    const offsetLeft = 10 - $('.timeline').offset().left
    const offsetTop = 20 - $('.timeline').offset().top

    this.setState({
      mediaInScene: {
        ...this.state.mediaInScene,
        [this.tmpIdForNewRelation]: {
          id: image.id,
          boxLeft: 0,
          boxTop: 0,
          boxWidth: 100,
          boxHeight: 100,
          guestLeft: 0,
          guestTop: 0,
          guestWidth: 100,
          guestHeight: 100,
          startTimeOffset: Math.round(((offsetLeft > 0 ? offsetLeft : 0) / this.editorDurationWidth * this.state.scaling) / 100) * 100,
          duration: duration,
          zIndex: Math.round(((offsetTop > 0 ? offsetTop : 0) / 150 * 150) / 50),
          idRelation: this.tmpIdForNewRelation, // Id le relation déjà existante (négative si aucune)
          ratio: true
        }
      },
      scaling: duration > this.state.scaling ? duration : this.state.scaling
    }, () => this.tmpIdForNewRelation -= 1)
  }

  listDrop = () => {
    //alert("on list drop");
    $('#scene-editor-list, #editor-positions').unbind('droppable')
    /*  $("#scene-editor-list, #editor-positions").droppable({
       drop: this.dropEventForViewPort,
     }); */
    $('.timeline').unbind('droppable')
    /* $(".timeline").droppable({
      drop: this.dropEventForTimeline,
    });  */
  }

  selecteMediaInScene = (idRelation) => {
    if (idRelation !== this.state.mediaSelected) {
      this.setState({
        mediaSelected: Number(idRelation)
      })
    }
  }

  /* fonction d'aide. Permet de supprimer un clé dans un object de façon immutable
   * TODO: mettre ça dans une classe OUTIL commune à toute l'app.
   */
  removeByKey (myObj, deleteKey) {
    return Object.keys(myObj)
      .filter((key) => key !== deleteKey)
      .reduce((result, current) => {
        result[current] = myObj[current]
        return result
      }, {})
  }

  removeMediaInScene = (idRelation) => {
    // Liste des relations supprimées
    if (idRelation >= 0)
      this.mediaDeleted.push(idRelation)

    this.setState({
      mediaInScene: this.removeByKey(this.state.mediaInScene, String(idRelation)),
      mediaSelected: undefined
    })
  }

  changeName = (e) => {
    const {value} = e.target
    this.setState({
      mediaEdit: {
        ...this.state.mediaEdit,
        name: value
      }
    })
  }

  submitChange = () => {
    // this.mediaDeleted : contient les id des relations à supprimer
    // this.state.mediaInScene contient les relations indexé par idRelation
    // this.state.mediaEdit: {id: id de la scene. -1 si ajout, name: nom}
    let relationsToSave = {}
    Object.keys(this.state.mediaInScene).forEach((key) => {
      const relation = this.state.mediaInScene[key]
      relationsToSave[key] = {
        id: key,
        guestMediaId: relation.id,
        duration: relation.duration,
        startTimeOffset: relation.startTimeOffset,
        boxLeft: relation.boxLeft,
        boxTop: relation.boxTop,
        boxWidth: relation.boxWidth,
        boxHeight: relation.boxHeight,
        guestLeft: relation.guestLeft,
        guestTop: relation.guestTop,
        guestWidth: relation.guestWidth,
        guestHeight: relation.guestHeight,
        endTimeOffset: 0,
        repetitionDelay: 0,
        zIndex: relation.zIndex
      }
    })
    this.setState({
      mediaSelected: undefined
    })
    //return;
    /* let searchField = "name";
    let obj = JSON.parse($("#tableData").val());
    let cpt=0;
    for (let i=0 ; i < obj.length ; i++)
    {
        if (obj[i][searchField] === this.state.mediaEdit.name) {
            cpt++;
        }
    }
    if(cpt>=1)
    {
      NotificationGenerator.raise('Ce nom existe déjà, veuillez écrire un nouveau nom.',  'error');
    }
    else
    { */
    // this.props.handleLoading(true)
    const data = new FormData()

    data.append('file', this.state.mediaInScene)
    //  data.append('name', "test scene")
    // console.log(this.state.mediaInScene)
    SceneService.store(data, {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'})
      .then(async () => {
        await openMessage({content: 'Upload...', contentAfter: 'Le chargement du fichier est un succès.'})
      })
      .catch(async () => {
        await openMessage({content: 'Upload...', contentAfter: 'Le chargement du fichier a échoué.', type: 'error', duration: 5})
      })
      .finally(() => {
        // this.props.handleLoading(false)
        //this.props.onRefresh()
      })


    /* this.props.actions.featPatchOrCreateFromEditor(this.mediaDeleted, relationsToSave, this.state.mediaEdit)
      .then(() => {
        this.context.router.push('/display-management/scene/')
      }) */
    // }
  }

  handleFormChange = (changedFields) => {
    this.updateRelation(this.state.mediaSelected, changedFields)
  }

  updateRelation = (idRelation, keyToChange) => {
    let updatedRelation = {...this.state.mediaInScene[idRelation]}
    Object.keys(keyToChange).forEach((key) => {
      updatedRelation[key] = keyToChange[key]
    })
    this.setState({
      mediaInScene: {
        ...this.state.mediaInScene,
        [idRelation]: {
          ...updatedRelation
        }
      }
    })
  }

  changeScaling = (pourcent) => {
    this.setState({
      scaling: Math.round((this.state.scaling * pourcent) / 1000) * 1000
    })
  }

  tick = () => {
    if (this.state.interval >= this.state.mediaEdit.duration) {
      clearInterval(this.timer)
      this.setState({
        mediaControls: 'pause'
      })
    } else {
      this.setState({
        interval: Date.now() - this.startTime
      })
    }
  }

  playScene = () => {
    this.setState({
      mediaControls: 'play'
    }, () => {
      this.startTime = Date.now() - this.state.interval
      clearInterval(this.timer)
      this.timer = setInterval(this.tick, 50)
    })
  }

  pauseScene = (mouse, callback) => {
    clearInterval(this.timer)
    this.setState({
      mediaControls: 'pause'
    }, callback)
  }

  rewindScene = () => {
    clearInterval(this.timer)
    this.setState({
      interval: 0,
      mediaControls: 'pause'
    }, () => this.setState({mediaControls: '0'}))
  }

  setSceneInterval = x => {
    // console.log('x = ' + x + ' editor duration = ' + this.editorDurationWidth + ' scaling = ' + this.state.scaling)
    const computedX = Math.round(x / this.editorDurationWidth * this.state.scaling)
    clearInterval(this.timer)
    //this.playScene();
    //   this.pauseScene(undefined, () =>
    this.setState({
      interval: computedX,
      mediaControls: 'pause'
    })
    //)
  }

  changeDuration = e => {
    // console.log(e)
    // console.log(this.state.mediaSelecte)
    let newMedias = this.state.mediaInScene
    newMedias[this.state.mediaSelected].duration = e === 1 ? 0 : -1
    this.setState({
      mediaInScene: newMedias
    })
  }

  onChangeScaling = val => {
    this.setState({
      scaling: val
    })
  }

  infoMedia (media) {
    //alert(media);
    // console.log(JSON.stringify(media))
    this.setState({
      actualmedia: media
    })
  }

  onClickTimeline = idRelation => {
    if (this.state.mediaSelected !== idRelation) {
      this.selecteMediaInScene(idRelation)
    }
  }

  mydrop () {
    alert('The p element was dropped')
  }

  handleSaveScene = () => {
    const { scene, mediaInScene } = this.state
    const sceneMediaKeys = Object.keys(mediaInScene)
    const sceneMedias = sceneMediaKeys.map(ms => mediaInScene[ms])
    const data = { name: scene.name, scene_medias: sceneMedias }

    console.log(data);

    this.setState({
      isFetching: true
    }, () => {
      SceneService.update(scene.id, data).then(async () => {
        this.loadScene();
        await openMessage({content: 'Mise à jour ...', contentAfter: 'Mise à jour effectuer avec succès.'})
      })
      .catch(async () => {
        await openMessage({content: 'Mise à jour ...', contentAfter: 'La mise à jour a échoué.', type: 'error', duration: 5})
      })
      .finally(() => {
        this.setState({ isFetching: false })
      })
    })
  }

  render () {
    let screenSparationsDivs = []

    Object.keys(this.state.mediaInScene).forEach((key) => {
      const media = this.state.mediaInScene[key].media
      const relation = this.state.mediaInScene[key]
      const k = relation.startTimeOffset <= this.state.interval && relation.startTimeOffset + relation.duration >= this.state.interval

      // Séparation de l'écran
      if ((media && k) || (relation.duration === 0 && media)) {
        screenSparationsDivs.push(
          <SceneEditorViewPort
            key={key}
            zIndex={relation.zIndex}
            editorHeight={this.editorHeight}
            editorWidth={this.editorWidth}
            ratio={relation.ratio}
            x={relation.boxLeft / 100 * this.editorWidth}
            y={relation.boxTop / 100 * this.editorHeight}
            width={relation.boxWidth / 100 * this.editorWidth}
            height={relation.boxHeight / 100 * this.editorHeight}
            onClick={() => {
              this.infoMedia(media)
              if (this.state.mediaSelected !== key)
                this.selecteMediaInScene(key)
            }}
            onResizeStop={(newHeight, newWidth) => {
              const newBoxHeight = newHeight + relation.boxTop > 100 ? 100 - relation.boxTop : newHeight
              const newBoxWidth = newWidth + relation.boxLeft > 100 ? 100 - relation.boxLeft : newWidth

              this.setState({
                mediaInScene: {
                  ...this.state.mediaInScene,
                  [key]: {
                    ...relation,
                    boxHeight: newBoxHeight,
                    boxWidth: newBoxWidth
                  }
                }
              })
            }}
            onDragStop={(newX, newY) => {
              this.setState({
                mediaInScene: {
                  ...this.state.mediaInScene,
                  [key]: {
                    ...relation,
                    boxTop: newY,
                    boxLeft: newX
                  }
                }
              })
            }}
            media={media}
            mediaControls={!isNaN(this.state.mediaControls) ? String((this.state.mediaControls - relation.startTimeOffset) % media.duration) : this.state.mediaControls}
            offset={relation.startTimeOffset}
            highlight={this.state.mediaSelected === key}
          />
        )
      }
    })

    return (
      <Layout className="display-management-content-layout">
        <Row>
          <Col className="gutter-row" span={4}>
            <MediaListContainer
              hasFiles
              dropEventForTimeline={this.dropEventForTimeline}
              dropEventForViewPort={this.dropEventForViewPort}
            />
          </Col>

          <Col className="gutter-row" span={15}>
            <div id="scene-list-container">
              {/** Cadre du centre. Là ou on depose les fichiers glissés déposer **/}
              <Row>
                <Col id="editor-positions">
                  {Object.keys(this.state.mediaInScene).length === 0 ? (
                    <div
                      onDrop={this.mydrop}
                      id="drop-empty-container"
                      style={{width: '170px', height: '90px'}}
                    >
                      <ArrowDownOutlined/>
                      <p> Déplacez des médias ici ! </p>
                    </div>
                  ) : (
                    <div>
                      {screenSparationsDivs}
                    </div>
                  )}
                </Col>
              </Row>

              <SceneEditorTimeline
                scaling={this.state.scaling}
                interval={this.state.interval}
                scene={this.state.mediaEdit}
                playScene={this.playScene}
                onClick={idRelation => {
                  if (this.state.mediaSelected !== idRelation) {
                    this.selecteMediaInScene(idRelation)
                  }
                }}
                pauseScene={this.pauseScene}
                rewindScene={this.rewindScene}
                onChangeScaling={(val) => {
                  this.setState({
                    scaling: val
                  })
                }}
                medias={this.state.mediaInScene}
                relations={this.state.mediaInScene}
                changeScaling={this.changeScaling}
                updateRelation={this.updateRelation}
                setSceneInterval={this.setSceneInterval}
                highlightSelected={this.state.mediaSelected}
                editorDurationWidth={this.editorDurationWidth}
              />
            </div>
          </Col>

          <Col className="gutter-row" span={5}>
            <div id="calendar-container" style={{padding: '1rem', backgroundColor: '#fff'}}>
              <Input
                type="text"
                value={this.state.scene.name}
                style={{ marginBottom: "1rem" }}
                onChange={({ target }) => {
                  this.setState(state => ({
                    ...state,
                    scene: {
                      ...state.scene,
                      name: target.value
                    }
                  }))
                }}
              />

              <Button
                block
                type="primary"
                onClick={this.handleSaveScene}
                loading={this.state.isFetching}
                disabled={this.state.scene.name <= 0}
                style={{padding: '5px', margin: '0 0 10px 0'}}
              >
                Sauvegarder
              </Button>

              <hr style={{opacity: .16}}/>

              {this.state.mediaSelected === undefined ? (<div>Cliquez sur un média pour modifier ces informations.</div>) : (
                <div>
                  <h2 style={{padding: '5px', wordBreak: 'break-word'}}>
                    {this.state.actualmedia ? this.state.actualmedia.name : ''}
                    <Button
                      size="small"
                      type="danger"
                      shape={'circle'}
                      style={{float: 'right'}}
                      onClick={() => this.removeMediaInScene(this.state.mediaSelected)}
                    >
                      <DeleteOutlined/>
                    </Button>
                  </h2>

                    {/*<SceneEditorForm */}
                    {/*  onChange={this.handleFormChange} */}
                    {/*  changeDuration={this.changeDuration} */}
                    {/*  mediaData={this.state.mediaInScene[this.state.mediaSelected]} */}
                    {/*/> */}

                  {/* <SceneEditorFormSaveVideo
                    onChange={this.handleFormChange}
                    changeDuration={this.changeDuration}
                    mediaData={this.state.mediaInScene[this.state.mediaSelected]}
                  /> */}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default withRouter(SceneEditorContainer)
