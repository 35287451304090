import axios from 'axios'
import {LoginService} from '../services/index'
import {openMessage} from '../Shared/Helper'

async function HandleError ({message, page, type = 'error'}) {
  window.location.replace(`${window.location.origin}/${page}`)
  LoginService.logOut()
  await openMessage({content: message, type, duration: 3})
}

// Request interceptor
axios.interceptors.request.use(async request => {
  const token = LoginService.getToken()
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const {status} = error.response

  if (status >= 500) {
    (async () => await HandleError({message: '500 – Erreur interne du serveur.', page: 'login'}))()
  }

  if (status === 401) {
    (async () => await HandleError({message: 'Votre jeton d\'accès a expiré, reconnectez-vous svp ...', page: 'login'}))()
  }

  return Promise.reject(error)
})
