import React from 'react'
import PropTypes from 'prop-types'

import {Form, Input, Button, Row, Col} from 'antd'

import {openMessage} from '../Helper'
import MediaTable from '../MediaTable'
import ModalContent from '../Modal'
import {SceneService} from '../../services'

const ACTION = {
  ADD_SCENE: 'ADD_SCENE'
  //EDIT_USER: 'EDIT_USER',
  //MODULE_USER: 'MODULE_USER',
  //PERMISSION_USER: 'PERMISSION_USER'
}
const reload = () => window.location.reload()

export default class SceneTable extends React.Component {
  static propTypes = {
    onNameEdit: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      previewIsVisible: false,
      previewWidth: 0,
      previewFile: undefined,
      file: undefined,
      showModal: false,
      scene: {
        id: null, user_id: null, scene_id: null, entite_id: null,
        name: null, duration: null, ratio_numerator: null, ratio_denominator: null
      },
      actionToShow: ACTION.ADD_SCENE,
      notification: {
        show: false,
        type: 'success',
        message: null
      },
      titleModal: 'ajout'
    }
  }

  handleOpenModal = actionToShow => this.setState({
    actionToShow,
    showModal: true
  })

  handleCloseModal = () => this.setState({
    //this.setState(prevState => ({
    //  ...prevState,
    scene: [],
    showModal: false,
    actionToShow: ACTION.ADD_SCENE
    //}))
  })

  onAdd = () => {
    this.setState({titleModal: 'Ajout d\'une scène'}, () => {
      this.handleOpenModal(ACTION.ADD_SCENE)
    })
  }

  handleOk = () => {
    this.handleCloseModal()
  }

  onAddScene = values => {
    this.setState({loading: true})

    SceneService.store(values).then(async ({data}) => {
      let success_msg = 'Scène ajoutée avec succès !'
      this.setState(
        {showModal: false},
        async () => {
          //await openMessage({content: 'Ajout...', contentAfter: data.message})
          await openMessage({content: 'Ajout...', contentAfter: success_msg})
          this.props.onRefresh()
          this.state.scene = []
          reload()
        }
      )
    }).catch(async ({response}) => {
      let msg = ''
      let {errors} = response.data

      if (errors.name) msg = errors.name.join(' ')

      this.setState(prevState => ({
        ...prevState,
        notification: {
          show: true,
          type: 'error',
          message: msg
        }
      }))
      await openMessage({content: 'Ajout...', contentAfter: msg})
    })
  }


  render () {
    const columns = [
      //MediaTable.ColumnModel.id,
      MediaTable.ColumnModel.name(this.props.onNameEdit),
      MediaTable.ColumnModel.ratio,
      MediaTable.ColumnModel.duration,
      MediaTable.ColumnModel.createdAt,
      MediaTable.ColumnModel.updatedAt

    ]

    const {titleModal, showModal, loading, notification, actionToShow} = this.state

    return (
      <div>
        {/*Modal to Add or Edit info user*/}
        <MediaTable title="Scènes" columns={columns} {...this.props} onAdd={this.onAdd}/>
        {/*Modal to Add info scene*/}
        <ModalContent
          width={390}
          style={null}
          footer={null}
          title={titleModal}
          visible={showModal}
          //handleOk={this.handleOk}
          handleCancel={reload}
        >
          <div>
            {actionToShow === ACTION.ADD_SCENE &&
            <SceneFormHelper
              loading={loading}
              initialValues={{name: ''}}
              onSubmit={this.onAddScene}
              style={{marginTop: notification.show ? '1.5rem' : ''}}
            />}
          </div>
        </ModalContent>

      </div>
    )
  }
}


const SceneFormHelper = ({loading, onSubmit, submitText = 'Ajouter', style, initialValues}) => {
  return (
    <Form
      name="basic"
      style={style}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        hasFeedback
        rules={[
          {required: true, message: 'Veuillez saisir le nom de la scène!'}
        ]}
      >
        <Input placeholder="Nom"/>
      </Form.Item>

      <Form.Item>
        <Row>
          <Col>
            <Button type="primary" htmlType="submit">
              {submitText}
            </Button>
          </Col>
        </Row>
      </Form.Item>

    </Form>
  )
}
