import React from 'react'
import {Modal} from 'antd'
import moment from 'moment'

import './ScreenListContainer.scss'
import {openMessage} from '../../Shared/Helper'
import ScreenTable from '../../Shared/ScreenTable'
import FooterModal from './components/FooterContent'
import ScreensContent from './components/ScreensContent'
import FormHoursScreen from './components/FormHoursScreen'
import AddScreenContent from './components/AddScreenContent'
import {AgendaService, SceneService, ScreenService} from '../../services'

const MODAL_CONTENT = {
  LIST_SCENE: 'LIST_SCENE',
  ADD_SCREEN: 'ADD_SCREEN',
  LIST_AGENDA: 'LIST_AGENDA',
  UPDATE_HOUR_SCREEN: 'UPDATE_HOUR_SCREEN'
}

export default class ScreenPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      screens: [],
      loading: true,
      visible: false,
      permission: [],
      selectedRowKeys: [],

      scenes: [],
      agendas: [],
      modal: {
        data: {
          id: null,
          name: null,
          status: null,
          hour_end: null,
          hour_start: null,
          created_at: null,
          updated_at: null,
          ratio_numerator: null,
          ratio_denominator: null,
          agenda: {
            id: null,
            name: null
          }
        },
        visible: false,
        confirmLoading: false,
        childrem: MODAL_CONTENT.ADD_SCREEN,
        title: 'Entrer le code à chiffre affiché par le téléviseur.'
      },

      addScreen: {
        name: '',
        code: '',
        hour_end: '',
        hour_start: '',
        scene_id: null,
        agenda_id: null,
        ratio_numerator: '1/6',
        ratio_denominator: '1/4'
      },
      nameValue: '',
      codeValue: '',
      confirmText: '',
      textFieldCode: '',
      textFieldName: '',
      agendaValue: null,
      confirmLoading: false
    }
  }

  componentDidMount () {
    (async () => {
      await this.handleGetScreens()
      await this.handleGetAgendas()
      this.setState({loading: false})
    })()
  }

  handleGetScreens = async () => {
    await ScreenService.all().then(({data}) => {
      this.setState({
        loading: false,
        screens: data.screens,
        permission: data.permission
      })
    })
  }

  handleGetAgendas = async () => {
    await AgendaService.all().then(({data}) => {
      this.setState({
        agendas: data.agendas,
        permission: data.permission
      })
    })
  }

  onAdd = () => {
    (async () => await this.loadScenes())()

    this.setState(state => ({
      modal: {
        ...state.modal,
        visible: true,
        childrem: MODAL_CONTENT.ADD_SCREEN
      }
    }))
  }

  handleChangeAddScreenName = ({target}) => {
    this.setState(state => ({
      addScreen: {
        ...state.addScreen,
        name: target.value
      }
    }))
  }

  handleAddScreenCodeChange = ({target}) => {
    this.setState(state => ({
      addScreen: {
        ...state.addScreen,
        code: target.value
      }
    }))
  }

  handleAddScreenSceneChange = (key, {value}) => this.setState(state => ({
    addScreen: {
      ...state.addScreen,
      scene_id: value
    }
  }))

  handleAddScreenHourEndChange = time => {
    this.setState(state => ({
      addScreen: {
        ...state.addScreen,
        hour_end: time
      }
    }))
  }

  handleAddScreenHourStartChange = time => {
    this.setState(state => ({
      addScreen: {
        ...state.addScreen,
        hour_start: time
      }
    }))
  }

  handleAdd = () => {
    const {addScreen} = this.state

    if (addScreen.name.length <= 0 || addScreen.code.length <= 0) {
      this.setState({
        textFieldCode: 'Obligatoire',
        textFieldName: 'Optionel',
        confirmText: `Veuillez entrer le code d'identification de votre télévisieur.`
      })
      return
    }

    this.setState(state => ({
      modal: {
        ...state.modal,
        confirmLoading: true
      }
    }))
  }

  handleCancel = () => {
    this.setState(state => ({
      modal: {
        ...state.modal,
        visible: false
      }
    }))
  }

  onDelete = async ({id}) => {
    await this.deleteFileById(id).then(async () => {
      this.setState({loading: true})
      await this.onRefresh().finally(() => this.setState({loading: false}))
    })

    await openMessage({content: 'suppression', contentAfter: 'Suppression effectuée avec succès.'})
  }

  deleteFileById = async (id) => await ScreenService.destroy(id)

  onDeleteSelection = async () => {
    const {selectedRowKeys} = this.state

    if (selectedRowKeys.length > 0) {
      this.setState({loading: true}, async () => {
        this.state.selectedRowKeys.forEach(fileId => {
          (async () => await this.deleteFileById(fileId))()
        })

        await openMessage({
          content: 'Suppression en cours ...',
          contentAfter: 'Suppression effectuée avec succès.'
        }).then(async () => await this.onRefresh())
      })
    } else {
      await openMessage({
        type: 'warning',
        content: 'Verification en cours ...',
        contentAfter: 'Il n\'a rien à supprimer. Ajoutez une sélection..'
      })
    }
  }

  onNameEdit = async ({id, name}) => {
    this.setState({loading: true})
    await ScreenService.update(id, {name}).then(async () => {
      await openMessage({
        content: 'Mise à jour ...',
        contentAfter: 'La mise à jour est un succès.'
      })
      await this.onRefresh()
      this.setState({loading: false})
    }).catch(async ({response}) => {
      if (response.status === 422) {
        await openMessage({
          content: 'Echec de mise à jour ...',
          contentAfter: response.data.errors.name.join(' '),
          type: 'error'
        })
      }
      this.setState({loading: false})
    })
  }

  onCodeEdit = async ({id, code}) => {
    this.setState({loading: true})

    await ScreenService.update(id, {code}).then(async () => {
      await openMessage({
        content: 'Mise à jour ...',
        contentAfter: 'La mise à jour est un succès.'
      })
      await this.onRefresh()
      this.setState({loading: false})
    }).catch(async ({response}) => {
      if (response.status === 422) {
        await openMessage({
          content: 'Echec de mise à jour ...',
          contentAfter: response.data.errors.name.join(' '),
          type: 'error'
        })
      }
      this.setState({loading: false})
    })
  }

  onRefresh = async () => await this.handleGetScreens()

  onScene = async (screen) => {
    this.setState(state => ({
      modal: {
        ...state.modal,
        visible: true,
        data: screen,
        childrem: MODAL_CONTENT.LIST_SCENE,
        title: `Modifier la scène pour l'écran "${screen.name}".`
      }
    }))

    await this.loadScenes()
  }

  loadScenes = async () => {
    if (this.state.scenes.length <= 0) {
      await SceneService.all().then(({data}) => {
        this.setState({scenes: data.scenes})
      })
    }
  }

  onSchedule = screen => {
    this.setState(state => ({
      modal: {
        ...state.modal,
        visible: true,
        childrem: MODAL_CONTENT.UPDATE_HOUR_SCREEN,
        title: `Modifier les horraires d'activité de l'écran "${screen.name}".`,
        data: screen
      }
    }))
  }

  toUpdate = (value, key) => {
    const {modal} = this.state

    let screens = [...this.state.screens]
    let newScreen = {...screens.find(s => s.id === modal.data.id)}
    // newScreen[key] = value?.format(format)
    // modal.data[key] = value.format(format)

    screens[screens.findIndex(s => s.id === modal.data.id)] = newScreen

    this.setState({screens})
  }

  handleSubmit = () => {
    let {modal, addScreen} = this.state
    this.setState({loading: true})

    if (modal.childrem === MODAL_CONTENT.LIST_SCENE) {
      const errorUpdateScreen = async () => {
        this.setState({
          loading: false
        })
        await openMessage({
          type: 'error',
          content: 'Echec de la mise à jour',
          contentAfter: 'La mise à jour a échouée.'
        })
      }
      const successUpdateScreen = async ({data}) => {
        await this.onRefresh()
        this.setState(state => ({
          modal: {
            ...state.modal,
            visible: false
          },
          loading: false,
          addScreen: {
            ...state.addScreen,
            name: '',
            code: '',
            hour_end: '',
            hour_start: '',
            agenda_id: null
          }
        }), async () => {
          await openMessage({
            type: 'success',
            contentAfter: data.message,
            content: 'Mise à jour en cours ...'
          })
        })
      }

      ScreenService.update(modal.data.id, {scene_id: modal.data.scene.id})
        .then(successUpdateScreen)
        .catch(errorUpdateScreen)
    }

    if (modal.childrem === MODAL_CONTENT.ADD_SCREEN) {
      const successAddScreen = async ({data}) => {
        await this.onRefresh()
        this.setState(state => ({
          modal: {
            ...state.modal,
            visible: false
          },
          loading: false,
          addScreen: {
            ...state.addScreen,
            name: '',
            code: '',
            hour_end: '',
            hour_start: '',
            scene_id: null,
            agenda_id: null
          }
        }))
        await openMessage({
          type: 'success',
          content: `Ajout d'un nouvelle écran en cours ...`,
          contentAfter: data.message
        })
      }
      const errorAddScreen = async ({response}) => {
        let errors = {}
        if (response.status === 422) {
          errors = response.data.errors
        }

        this.setState({loading: false}, async () => {
          await openMessage({
            type: 'error',
            content: `Une erreur s'est produite. Remplissez tous les champs.`,
            contentAfter: Object.values(errors)[0]
          })
        })
      }

      ScreenService.store(addScreen)
        .then(successAddScreen)
        .catch(errorAddScreen)
    }

    if (modal.childrem === MODAL_CONTENT.UPDATE_HOUR_SCREEN) {
      ScreenService.update(modal.data.id, {
        hour_start: modal.data.hour_start,
        hour_end: modal.data.hour_end
      })
        .then(async ({data}) => {
          await this.onRefresh()
          this.setState(state => ({
            loading: false,
            modal: {
              ...state.modal,
              visible: false
            }
          }))
          await openMessage({content: 'Mise à jour en cours ...', type: 'success', contentAfter: data.message})
        })
        .catch(async () => {
          this.setState({loading: false})
          await openMessage({
            type: 'error',
            content: 'Echec de la mise à jour',
            contentAfter: 'La mise à jour est une erreur.'
          })
        })
    }
  }

  onSelectChange = selectedRowKeys => this.setState({selectedRowKeys})

  render () {
    const {
      modal,
      loading,
      scenes,
      screens,
      addScreen,
      permission,
      textFieldName,
      textFieldCode,
      selectedRowKeys
    } = this.state

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <div>
        {modal.visible && (
          <Modal
            footer={[
              <FooterModal
                loading={loading}
                handleAdd={this.handleSubmit}
                handleCancel={this.handleCancel}
              />
            ]}
            title={modal.title}
            visible={modal.visible}
            onCancel={this.handleCancel}
            confirmLoading={modal.confirmLoading}
          >
            <>
              {modal.childrem === MODAL_CONTENT.LIST_SCENE && (
                <ScreensContent
                  scenes={scenes}
                  defaultValue={modal.data.scene.id}
                  onChange={(key, {value, children}) => this.setState(state => ({
                    modal: {
                      ...state.modal,
                      data: {
                        ...state.modal.data,
                        scene: {
                          ...state.modal.data.scene,
                          id: value,
                          name: children
                        }
                      }
                    }
                  }))}
                />
              )}
              {modal.childrem === MODAL_CONTENT.ADD_SCREEN && (
                <AddScreenContent
                  scenes={scenes}
                  addScreen={addScreen}
                  handleAdd={this.handleAdd}
                  textFieldCode={textFieldCode}
                  textFieldName={textFieldName}
                  handleAddScreenCodeChange={this.handleAddScreenCodeChange}
                  handleChangeAddScreenName={this.handleChangeAddScreenName}
                  handleAddScreenSceneChange={this.handleAddScreenSceneChange}
                  handleAddScreenHourEndChange={this.handleAddScreenHourEndChange}
                  handleAddScreenHourStartChange={this.handleAddScreenHourStartChange}
                />
              )}
              {modal.childrem === MODAL_CONTENT.UPDATE_HOUR_SCREEN && (
                <FormHoursScreen
                  onChangeEndAt={value => this.toUpdate(value, 'hour_start')}
                  onChangeStartAt={value => this.toUpdate(value, 'hour_start')}
                  defaultValueEndAt={moment(modal.data.hour_end, 'HH:mm')}
                  defaultValueStartAt={moment(modal.data.hour_start, 'HH:mm')}
                />
              )}
              <div style={{color: 'red'}}>{this.state.confirmText}</div>
            </>
          </Modal>
        )}

        <ScreenTable
          loading={loading}
          onAdd={this.onAdd}
          dataSource={screens}
          permission={permission}
          onScene={this.onScene}
          onDelete={this.onDelete}
          onRefresh={this.onRefresh}
          rowSelection={rowSelection}
          onSchedule={this.onSchedule}
          onCodeEdit={this.onCodeEdit}
          onNameEdit={this.onNameEdit}
          permissions={{
            create: 'create_file',
            update: 'update_file',
            delete: 'delete_file'
          }}
          onDeleteSelection={this.onDeleteSelection}
        />
      </div>
    )
  }
}
