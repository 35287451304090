import React from 'react'

import {groupUsersService} from '../../services'
import {openMessage} from '../../Shared/Helper'
import GroupUsersTable from './components/GroupUsersTable/GroupUserTable'

export default class GroupUsersTableContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groupUsers: [],
      file: null,
      previewWidth: 0,
      loading: true,
      selectedRowKeys: []
    }
  }

  componentDidMount () {
    (async () => await this.handleGetUsers())()
  }

  handleGetUsers = async () => {
    const {data} = await groupUsersService.all().finally(() => this.setState({loading: false}))
    this.setState({
      groupUsers: data.group_users
    })
  }

  onDeleteFileById = async (id) => await groupUsersService.destroy(id)

  onDelete = async (user) => {
    await this.onDeleteFileById(user.id)
    await openMessage({content: 'Suppression ...', contentAfter: 'Suppression effectuée avec succès.'})
    await this.onRefresh()
  }

  onDeleteSelection = async () => {
    this.state.selectedRowKeys.forEach(Id => {
      (async () => await this.onDeleteFileById(Id))()
    })

    await openMessage({content: 'Suppression ...', contentAfter: 'Suppression effectuée avec succès.'})
    await this.onRefresh()
  }

  onEdit = async ({id, name}) => {
    await groupUsersService.update(id, {name}).then(async () => {
      await openMessage(
        {content: 'Mise à jour...', contentAfter: 'Mise à jour effectuée avec succès.'}
      )
      await this.onRefresh()
    }).catch(async () => {
      await openMessage(
        {content: 'Echec de mise à jour...', contentAfter: 'La mise à jour n\'a pas marcher.', type: 'error'}
      )
    })
  }

  onUnlock = async (user) => {
    await groupUsersService.unlock(user.id).then(async () => {
      await openMessage(
        {content: 'Déblocage...', contentAfter: 'Utilisateur débloqué avec succès.'}
      )
      await this.onRefresh()
    }).catch(async () => {
      await openMessage(
        {content: 'Echec...', contentAfter: 'Echec de déblocage de l\'utilisateur.', type: 'error'}
      )
    })
  }

  onlock = async (user) => {
    await groupUsersService.lock(user.id).then(async () => {
      await openMessage(
        {content: 'Blocage...', contentAfter: 'Utilisateur bloqué avec succès.'}
      )
      await this.onRefresh()
    }).catch(async () => {
      await openMessage(
        {content: 'Echec', contentAfter: `Echec de blocage de l'utilisateur.`, type: 'error'}
      )
    })
  }

  onRefresh = async () => {
    this.setState({loading: true})
    await this.handleGetUsers()
  }

  onNameEdit = async ({id, name}) => {
    this.setState({loading: true})
    groupUsersService.update(id, {name}).then(async () => {
      await this.onRefresh()
      await openMessage({content: 'Mise à jour...', contentAfter: 'Mise à jour effectuée avec succès.'})
    })
      .catch(async () => {
        await openMessage(
          {content: 'Echec de mise à jour...', contentAfter: 'La mise à jour n\'a pas marcher.', type: 'error'}
        )
      })
      .finally(() => this.setState({loading: false}))
  }

  onSelectChange = selectedRowKeys => this.setState({selectedRowKeys})

  render () {
    const {groupUsers, selectedRowKeys, loading} = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <GroupUsersTable
        size="small"
        loading={loading}
        isDeleting={loading}
        isUnlocked={loading}
        onDelete={this.onDelete}
        dataSource={groupUsers}
        onRefresh={this.onRefresh}
        rowSelection={rowSelection}
        onNameEdit={this.onNameEdit}
        onDeleteSelection={this.onDeleteSelection}
      />
    )
  }
}
