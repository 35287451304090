export const baseUrl = process.env.NODE_ENV === 'production' ? 'http://beta.senitdev.com/api' : 'http://127.0.0.1:8000/api';

const ROUTES = {
  FILE: {
    SHOW: `${baseUrl}/files`,
    EDIT: `${baseUrl}/files`,
    INDEX: `${baseUrl}/files`,
    STORE: `${baseUrl}/files`,
    DESTROY: `${baseUrl}/files`
  },
  AUTH: {
    USER: `${baseUrl}/user`,
    LOGIN: `${baseUrl}/auth/login`,
    LOGOUT: `${baseUrl}/auth/logout`,
    UPDATE: `${baseUrl}/auth/update`,
    REGISTER: `${baseUrl}/auth/register`
  },
  USER: {
    SHOW: `${baseUrl}/users`,
    EDIT: `${baseUrl}/users`,
    INDEX: `${baseUrl}/users`,
    STORE: `${baseUrl}/users`,
    DESTROY: `${baseUrl}/users`,
    LOCK: `${baseUrl}/users`,
    UNLOCK: `${baseUrl}/users`
  },
  SCENE: {
    INDEX: `${baseUrl}/scenes`,
    DESTROY: `${baseUrl}/scenes`,
    UPDATE: `${baseUrl}/scenes`,
    STORE: `${baseUrl}/scenes`,
    SHOW: `${baseUrl}/scenes`
  },
  SCREEN: {
    INDEX: `${baseUrl}/screens`,
    STORE: `${baseUrl}/screens`,
    SHOW: `${baseUrl}/screens`,
    UPDATE: `${baseUrl}/screens`,
    DESTROY: `${baseUrl}/screens`
  },
  AGENDA: {
    INDEX: `${baseUrl}/agendas`,
    SHOW: `${baseUrl}/agendas`,
    DESTROY: `${baseUrl}/agendas`,
    UPDATE: `${baseUrl}/agendas`,
    STORE: `${baseUrl}/agendas`
  },
  HORLOGE: {
    INDEX: `${baseUrl}/horloges`
  },
  GROUP_USERS: {
    EDIT: `${baseUrl}/group/users`,
    INDEX: `${baseUrl}/group/users`,
    SHOW: `${baseUrl}/group/users`,
    STORE: `${baseUrl}/group/users`,
    DESTROY: `${baseUrl}/group/users`
  },
  USER_ROLE: {
    INDEX: `${baseUrl}/management/roles`
  },
  PASSWORD: {
    STORE: `${baseUrl}/auth/reset-password`
  },
  USER_PERMISSION: {
    INDEX: `${baseUrl}/management/permissions`
  }
}

export default ROUTES
