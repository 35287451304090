import React from 'react'
import {Image} from 'antd'
import PropTypes from 'prop-types'

import './FileTable.scss'
import ModalContent from '../Modal'
import FileUpload from './FileUpload'
import MediaTable from '../MediaTable'
import TitleBar from '../../Shared/TitleBar'
import {detailFile} from '../Helper'

export default class FileTable extends React.Component {
  static propTypes = {
    onNameEdit: PropTypes.func
  }

  static ColumnModel = {
    resolution: {
      title: 'Résolution',
      key: 'resolution',
      render: (text, file) => `${file.width}x${file.height} (px)`
    },
    weight: {
      title: 'Poids',
      key: 'weight',
      sorter: (a, b) => b.weight - a.weight,
      render: (text, file) => detailFile(file.weight)
    },
    mimetype: {
      title: 'mimetype',
      dataIndex: 'mimetype',
      key: 'mimetype',
      sorter: (a, b) => b.mimetype.localeCompare(a.mimetype)
    }
  }

  constructor (props) {
    super(props)

    this.state = {
      previewIsVisible: false,
      previewWidth: 0,
      previewFile: undefined,
      file: undefined,
      showModal: false
    }
  }

  openFileUpload = () => {
    this.fileUpload.open()
  }

  openPreview = file => {
    const maxPreviewWidth = 768
    const maxPreviewHeight = 432

    // Calcul la largeur de la preview en fonction des dimensions du fichier et des dimensions maximales
    const widthRatio = file.width / maxPreviewWidth
    const heightRatio = file.height / maxPreviewHeight
    const largerRatio = widthRatio > heightRatio ? widthRatio : heightRatio
    const previewWidth = largerRatio > 1 ? file.width / largerRatio : file.width

    this.setState({
      file,
      showModal: true,
      previewIsVisible: true,
      previewWidth
    })
  }

  modalViewClose = () => {
    this.setState({
      previewIsVisible: false,
      previewWidth: 0,
      previewFile: undefined,
      file: undefined,
      showModal: false
    }, () => {
      if (this.videoNode) this.videoNode.pause()
    })
  }

  isImage = () => this.state.file && this.state.file.mimetype.includes('image')

  isVideo = () => this.state.file && this.state.file.mimetype.includes('video')

  render () {
    const columns = [
      MediaTable.ColumnModel.name(this.props.onNameEdit),
      FileTable.ColumnModel.resolution,
      FileTable.ColumnModel.weight,
      FileTable.ColumnModel.mimetype,
      MediaTable.ColumnModel.ratio,
      MediaTable.ColumnModel.createdAt,
      MediaTable.ColumnModel.image()
    ]

    const {file, showModal} = this.state
    const {dataSource, onRefresh} = this.props

    return (
      <div>
        {/* Title page */}
        <TitleBar title="Fichiers"/>

        {/* Uploader file */}
        <FileUpload
          onRefresh={onRefresh}
          ref={ref => this.fileUpload = ref}
          handleLoading={this.props.handleLoading}
        />

        {/* Show all files*/}
        <MediaTable
          {...this.props}
          columns={columns}
          dataSource={dataSource}
          onAdd={this.openFileUpload}
          onPreview={this.openPreview}
        />

        {/*Modal to read file*/}
        {showModal && <ModalContent
          footer={false}
          visible={showModal}
          title={file && file.name}
          handleCancel={this.modalViewClose}
        >
          {this.isImage() && <Image
            src={file.path}
            alt={file.path}
            style={{width: '100%', height: '100%', backgroundColor: '#fff'}}
          />}

          {this.isVideo() && <video
            controls
            src={file.path}
            ref={node => this.videoNode = node}
            style={{width: '100%', height: '100%', backgroundColor: '#fff'}}
          />}
        </ModalContent>}
      </div>
    )
  }
}
