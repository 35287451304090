import React from 'react'
import {Modal, Button} from 'antd'

export default class ModalContent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true}, this.props.handleOk())
  }

  render () {
    const {
      children,
      handleOk,
      handleCancel,
      width = 700,
      style = {
        top: 40
      },
      footer = true,
      title = 'Title',
      visible = false,
      disabledOkButton = false,
      titleBackButton = 'Annuler',
      titleSubmitButton = 'Soumettre',
      footerContent = [
        <Button
          key="back"
          onClick={handleCancel}
        >{titleBackButton}</Button>,
        <Button
          key="submit"
          type="primary"
          loading={this.state.loading}
          onClick={this.handleSubmit}
          disabled={disabledOkButton}
        >{titleSubmitButton}</Button>
      ]
    } = this.props

    return <>
      {visible && <Modal
        title={title}
        style={style}
        width={width}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={footer && footerContent}
      >
        {children}
      </Modal>
      }
    </>
  }
}
