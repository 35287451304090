import React, {useState, useEffect} from 'react'
import {Alert, Form, Input, Button} from 'antd'
import MailOutlined from '@ant-design/icons/MailOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import PhoneOutlined from '@ant-design/icons/PhoneOutlined'

import {ProfileService, PasswordResetService} from '../../../services'
import {openMessage} from '../../../Shared/Helper'

const RULES = {
  FIRST_NAME: [
    {required: true, message: `Saisir votre prénom`}
  ],
  LAST_NAME: [
    {required: true, message: `Saisir votre nom`}
  ],
  EMAIL: [
    {required: true, message: 'Saisir votre addresse email'},
    {type: 'email', message: 'Saisir une addresse email valide'}
  ],
  PHONE: [
    {required: true, message: 'Saisir votre numéro de téléphone'}
  ]
}
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 8
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 16
    }
  }
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
}

export const Notification = ({style = {}, message, type = 'success', showIcon = true, closable = true}) => {
  return (
    <div style={style}>
      <Alert
        type={type}
        style={style}
        message={message}
        showIcon={showIcon}
        closable={closable}
      />
    </div>
  )
}

export const HandleFormUser = () => {
  const [user, setUser] = useState(null)
  const [fields, setFields] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationType, setNotificationType] = useState('success')

  const handleGetUser = async () => {
    setLoading(true)
    const {data} = await ProfileService.getUserFromApi()
    setUser(data)

    setFields({
      phone: {value: data.phone},
      email: {value: data.email},
      last_name: {value: data.last_name},
      first_name: {value: data.first_name}
    })
    setLoading(false)
  }

  useEffect(() => {
    (async () => await handleGetUser())()
  })

  return {
    user,
    fields,
    loading,
    showNotification,
    notificationType,
    notificationMessage,
    setShowNotification,
    setNotificationType,
    setNotificationMessage
  }
}

export function UserForm ({layout, userId, initialValues, onSubmitEditing}) {
  const [loading, setLoading] = useState(false)

  const onFinish = values => {
    setLoading(true)

    ProfileService.updateProfile(userId, values).then(({data}) => {
      ProfileService.setUser(data)
      openMessage({
        contentAfter: data.message,
        content: 'Mise à jour en cours ....'
      }).then(() => {
      })

      onSubmitEditing(data)
    }).catch(error => {
      console.log(error)
      openMessage({
        type: 'error',
        content: 'Mise à jour en cours ....',
        contentAfter: 'Une erreur est survenu'
      }).then(() => {
      })
    }).finally(() => {
      setTimeout(() => setLoading(false), 500)
    })
  }

  return (
    <Form
      {...layout}
      name="basic"
      scrollToFirstError
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Form.Item
        label="Nom"
        name="last_name"
        rules={RULES.LAST_NAME}
      >
        <Input addonBefore={<UserOutlined/>}/>
      </Form.Item>
      <Form.Item
        label="Prénom"
        name="first_name"
        rules={RULES.FIRST_NAME}
      >
        <Input addonBefore={<UserOutlined/>}/>
      </Form.Item>
      <Form.Item
        label="Numéro de téléphone"
        name="phone"
        rules={RULES.PHONE}
      >
        <Input addonBefore={<PhoneOutlined/>}/>
      </Form.Item>
      <Form.Item
        name="email"
        rules={RULES.EMAIL}
        label="Adresse email"
      >
        <Input addonBefore={<MailOutlined/>} placeholder="Votre adresse email"/>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" loading={loading} htmlType="submit">
          Soumettre
        </Button>
      </Form.Item>
    </Form>
  )
}

export function UseChangePasswordUser ({email}) {
  const [loading, setLoading] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationType, setNotificationType] = useState('success')

  const onFinish = values => {
    setLoading(true)
    PasswordResetService.reset({
      ...values,
      ...{
        email,
        'new_password': values['new_password'],
        'password_confirmation': values['password'],
        'new_password_confirmation': values['new_password']
      }
    })
      .then(({data}) => {
        setShowNotification(true)
        setNotificationType('success')
        setNotificationMessage(data.message)
      })
      .catch(error => {
        setShowNotification(true)
        setNotificationType('error')
        setNotificationMessage(error.response.data.message)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
  }

  const onFinishFailed = () => {
  }

  return (
    <div>
      {showNotification && <Notification
        type={notificationType}
        message={notificationMessage}
      />}

      <Form
        style={{marginTop: '1.5rem'}}
        {...formItemLayout}
        scrollToFirstError
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="password"
          label="Actuel mot de passe"
          rules={[{required: true, message: 'Veuillez saisir votre actuel mot de passe!'}]}
          hasFeedback
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item
          name="new_password"
          label="Nouveau mot de passe"
          hasFeedback
          rules={[
            {required: true, message: 'Veuillez saisir le nouveau mot de passe!'}
          ]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirmation"
          dependencies={['new_password']}
          hasFeedback
          rules={[
            {required: true, message: 'Veuillez saisir la confirmation du nouveau mot de passe!'},
            ({getFieldValue}) => ({
              validator (_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve()
                }

                return Promise.reject('Les deux mots de passe que vous avez saisis ne correspondent pas.')
              }
            })
          ]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Changer
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
