import React from 'react'
import {Button, Row, Col, Switch, Tooltip} from 'antd'
import millisec from 'millisec'
import {
  LockOutlined,
  UnlockOutlined,
  SearchOutlined,
  StepBackwardOutlined,
  PauseOutlined,
  CaretRightOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'

import SceneEditorTimelineItem from './SceneEditorTimelineItem'
import SceneEditorTimelineCursor from './SceneEditorTimelineCursor'
import SceneEditorTimelineDurationMarque from './SceneEditorTimelineDurationMarque'

export default class SceneEditorTimeline extends React.Component {
  state = {
    magnetX: -1,
    maxZindex: 0,
    magnetIsActive: true,
    DOMitemsDimensions: {}
  }

  componentDidMount () {
    document.body.addEventListener('mouseup', () => this.markMouseDown = false)
  }

  componentDidUpdate (prevProps) {
    if (!(prevProps.relations === this.props.relations)) {
      let maxZindex = 0
      Object.keys(this.props.relations).forEach((key) => {
        if (this.props.relations[key].zIndex > maxZindex)
          maxZindex = this.props.relations[key].zIndex
      })

      this.setState({
        maxZindex: maxZindex
      })
    }
  }

  getCurrentFormatedTick = () => millisec(this.props.interval).format('hh [h] : mm [m] : ss [s]')

  updateCurrentMagnetX = (magnetX) => this.setState({magnetX: magnetX})

  seekCursor = (e) => this.props.setSceneInterval(e.pageX - document.getElementById('mark-duration').getBoundingClientRect().left)

  markMouseDown = false

  render () {
    let rnd = {}
    let items = []
    const {
      scene,
      scaling,
      medias,
      relations,
      interval: timeInterval,
      editorDurationWidth,
      highlightSelected: mediaSelected
    } = this.props
    const sceneDuration = scene.duration
    const maxZindex = this.state.maxZindex + 2

    Object.keys(relations).forEach((key) => {
      items.push(
        <SceneEditorTimelineItem
          key={key}
          scale={100}
          itemsRef={rnd}
          scaling={scaling}
          media={medias[key]}
          relation={relations[key]}
          maxZindex={maxZindex}
          onClick={this.props.onClick}
          isSelected={key === mediaSelected}
          updateRelation={this.props.updateRelation}
          magnetIsActive={this.state.magnetIsActive}
          editorDurationWidth={editorDurationWidth}
          ref={(ref) => rnd[relations[key].idRelation] = ref}
          setCurrentMagnetX={this.updateCurrentMagnetX}
        />
      )
    })

    const scalingS = scaling / 1000

    const markTime = Math.floor(scalingS / 15)
    const numberSeparation = scalingS / markTime

    let markSeparators = []
    for (let i = 0; i < numberSeparation; i++) {
      const left = i / numberSeparation * 100
      let width = (i + 1) / numberSeparation * 100 - left

      markSeparators.push(
        <span
          key={i}
          style={{
            left: left + '%',
            width: width + '%'
          }}
        >
          <span>
            {i * markTime}
          </span>
        </span>
      )
    }

    return (
      <div id="editor-duration-menu">
        {/*Editor duration indicator*/}
        <Row>
          {/*Active dragable elements*/}
          <Col span="6">
            <Tooltip title="Active/désactive le magnetisme lors des déplacements des éléments dans la timeline.">
              <Switch
                defaultChecked
                checkedChildren={<LockOutlined/>}
                unCheckedChildren={<UnlockOutlined/>}
                onChange={checked => this.setState({magnetIsActive: checked})}
              />
            </Tooltip>
          </Col>
          {/*Buttons actions timeline*/}
          <Col span="12">
            <Button onClick={this.props.rewindScene}>
              <StepBackwardOutlined/>
            </Button>

            <Button onClick={this.props.pauseScene}>
              <PauseOutlined/>
            </Button>

            <Button onClick={this.props.playScene}>
              <CaretRightOutlined/>
            </Button>

            <span className="timerDisplayer">
              {this.getCurrentFormatedTick()}
            </span>
          </Col>
          {/*Zoom actions buttons*/}
          <Col span="6">
            <Button
              onClick={() => {
                if (!(this.props.scaling <= 16000))
                  this.props.changeScaling(0.9)
              }}
            >
              <MinusCircleOutlined/>
            </Button>

            <span>
              <SearchOutlined/> {scalingS}[s]
            </span>

            <Button onClick={() => this.props.changeScaling(1.1)}>
              <PlusCircleOutlined/>
            </Button>
          </Col>
        </Row>
        {/*Scene Editor Timeline Duration Marque*/}
        <Row style={{position: 'relative', margin: '.5rem 0 0 0'}}>
          <SceneEditorTimelineDurationMarque
            scaling={scaling}
            sceneDuration={sceneDuration}
            editorDurationWidth={editorDurationWidth}
          />
          <SceneEditorTimelineCursor
            scaling={scaling}
            height={20 + 50 * (maxZindex)}
            editorDurationWidth={editorDurationWidth}
            onDrag={(offsetX) => this.props.setSceneInterval(offsetX)}
            x={Math.round((timeInterval) / scaling * editorDurationWidth)}
          />
        </Row>
        <Row className="timeline" style={{height: 50 * (maxZindex) + 'px', backgroundSize: editorDurationWidth + 'px 50px'}}>
          {items}
          {this.state.magnetX > -1 ? (
            <div className="magnet" style={{height: 50 * maxZindex + 'px', marginLeft: this.state.magnetX - 1 + 'px'}}/>
          ) : null}
        </Row>
      </div>
    )
  }
}
