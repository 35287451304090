import React from 'react'
import {
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  DesktopOutlined,
  AppstoreOutlined,
  UsergroupDeleteOutlined
} from '@ant-design/icons'
import {
  FilesPage,
  ScenePage,
  UsersPage,
  ScenesPage,
  ProfilePage,
  ScreenPage,
  // AgendaPage,
  // AgendasPage,
  SceneEditorPage,
  GroupUsersPage,
  // AgendaNewContainer
} from '../Pages'

export const isAuthenticated = () => true
export const capitalizeFirstLetter = str => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`

export const OTHERS_ROUTES = {
  // AGENDA: {
  //   title: 'Agenda',
  //   url: '/agendas/:id',
  //   icon: <CalendarOutlined/>,
  //   component: <AgendaPage/>
  // },
  // AGENDA_CREATE: {
  //   title: 'Création d\'un Agenda',
  //   url: '/agenda/new',
  //   icon: <CalendarOutlined/>,
  //   component: <AgendaNewContainer/>
  // },
  NEW_SCENE: {
    title: 'Scene',
    url: '/scenes/new',
    icon: <AppstoreOutlined/>,
    component: <ScenesPage/>
  },
  EDIT_SCENE: {
    title: 'Scene',
    url: '/scenes/:id',
    icon: <AppstoreOutlined/>,
    component: <SceneEditorPage/>
  }
}

export const LINKS_PATH = {
  FILE: {
    title: 'Fichiers',
    url: '/files',
    icon: <FileOutlined/>,
    component: <FilesPage/>
  },
  SCENE: {
    title: 'Scènes',
    url: '/scenes',
    icon: <AppstoreOutlined/>,
    component: <ScenePage/>
  },
  SCREEN: {
    title: 'Écrans',
    url: '/screens',
    icon: <DesktopOutlined/>,
    component: <ScreenPage/>
  },
  // AGENDAS: {
  //   title: 'Agendas',
  //   url: '/agendas',
  //   icon: <CalendarOutlined/>,
  //   // component: <AgendasPage/>
  // },
  PROFILE: {
    title: 'Profil',
    url: '/profile',
    icon: <UserOutlined/>,
    component: <ProfilePage/>
  },
  Users: {
    title: 'Utilisateurs',
    url: '/users',
    icon: <TeamOutlined/>,
    component: <UsersPage/>
  },
  GroupeUsers: {
    title: `Groupe d'Utilisateurs`,
    url: '/groupes/users',
    icon: <UsergroupDeleteOutlined/>,
    component: <GroupUsersPage/>
  }
}
const LINKS = {
  ...LINKS_PATH,
  ...OTHERS_ROUTES
}

export default LINKS
