import resource from '../utils/resource'
import routesBackend from '../utils/routeBackend'

const PasswordResetService = {
  /**
   * Save files
   *
   * @returns {Promise<*>}
   */
  reset: async (data: Object, config = null) => await resource.post(routesBackend.PASSWORD.STORE, data, config)
}

export default PasswordResetService
