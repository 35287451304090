import React, {memo} from 'react'
import {Button, Space} from 'antd'

const FooterModal = memo(({loading, handleAdd, handleCancel, textCancel = 'Annuler', textOk = 'Sauvegarder'}) => {
  return (
    <Space direction="horizontal" align="end">
      <Button
        key="back"
        disabled={loading}
        onClick={handleCancel}
      >{textCancel}</Button>

      <Button
        key="submit"
        type="primary"
        loading={loading}
        disabled={loading}
        onClick={handleAdd}
      >{textOk}</Button>
    </Space>
  )
})

export default FooterModal
