import React from 'react'
import {Row, Col, Card, Divider} from 'antd'

import './index.css'
import {ProfileService} from '../../services'
import {UserForm, UseChangePasswordUser} from './UserForm'

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10
  }
}

export default class ProfileTableContainer extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      visible: false,
      loading: true,
      fields: {
        phone: {value: ''},
        email: {value: ''},
        last_name: {value: ''},
        first_name: {value: ''}
      },
      user: {
        id: null,
        phone: null,
        email: null,
        last_name: null,
        first_name: null
      }
    }
  }

  componentDidMount () {
    (async () => await this.handleGetUser())()
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async handleGetUser () {
    const {data} = await ProfileService.getUserFromApi()
    const {phone, email, last_name, first_name} = data
    this.setState({
      loading: false,
      user: data,
      fields: {
        phone: {value: phone},
        email: {value: email},
        last_name: {value: last_name},
        first_name: {value: first_name}
      }
    })
  }

  onSubmitEditing = (user) => {
    this.setState({
      user
    })
  }

  render () {
    const {user, loading, fields} = this.state
    const {first_name, last_name, phone, email} = fields

    return (
      <div>
        <Card
          title="Profil"
          loading={loading}
        >
          <Row>
            <Col span={10}>
              <div className="custom-card">
                <h4>Nom: <b style={{display: 'inline'}}>{user.last_name}</b></h4>
                <h4>Prénom: <b style={{display: 'inline'}}>{user.first_name}</b></h4>
                <h4>Tél: <b style={{display: 'inline'}}>{user.phone}</b></h4>
                <h4>Email: <b style={{display: 'inline'}}>{user.email}</b></h4>
              </div>
            </Col>

            <Col span={14}>
              <UserForm
                layout={layout}
                userId={user.id}
                initialValues={{
                  first_name: first_name.value,
                  last_name: last_name.value,
                  phone: phone.value,
                  email: email.value
                }}
                tailLayout={tailLayout}
                onSubmitEditing={this.onSubmitEditing}
              />
            </Col>
          </Row>

          <Divider orientation="left">
            Changement de mot de passe
          </Divider>

          <Row>
            <Col span={12}>
              <UseChangePasswordUser
                email={email.value}
              />
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}
