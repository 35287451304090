import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {EditOutlined, MailOutlined, AppstoreOutlined} from '@ant-design/icons';
import {Menu, Space, Form, Button, Checkbox, Tooltip, Row, Col, Popconfirm} from 'antd';

import Loader from '../../../../Shared/Loader';
import TitleBar from '../../../../Shared/TitleBar';
import ModalContent from '../../../../Shared/Modal';
import {openMessage} from '../../../../Shared/Helper';
import MediaTable from '../../../../Shared/MediaTable';
import {useFetch} from '../../../../utils/UseFetchData';
import routesBackend from '../../../../utils/routeBackend';
import {LoginService, ProfileService} from '../../../../services';
import EditableCell from '../../../../Shared/DataTable/EditableCell/EditableCell';

const ACTION = {
  ADD_USER: 'ADD_USER',
  LIST_USERS: 'LIST_USERS',
  EDIT_USER: 'EDIT_USER',
  MODULE_USER: 'MODULE_USER',
  PERMISSION_USER: 'PERMISSION_USER'
}
export default class GroupUserTable extends React.Component {
  static propTypes = {
    onNameEdit: PropTypes.func
  }

  static ColumnModel = {
    // Modèle pour le champ "id"
    id: {
      title: <input onClick={this.selectAll} id="selectall" type="checkbox"/>,
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => b.id - a.id,
      render: (text, user) => <input className="ads_Checkbox" value={user.id} type="checkbox"/>
    },
    name: (onEdit = null) => {
      const render = onEdit ?
        (text, user) => <EditableCell record={user} field="name" onEdit={onEdit}/>
        : (text, user) => user.name

      return {
        title: 'Nom',
        dataIndex: 'name',
        key: 'name',
        searchable: true,
        sorter: (a, b) => b.name - a.name,
        render
      }
    },
    user: (onEditUserName) => {
      return {
        key: 'user',
        title: 'Utilisateur',
        dataIndex: 'user',
        render: (text, groupUser) => <Tooltip
          title="Droit"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            icon={<EditOutlined/>}
            onClick={() => onEditUserName(groupUser)}
          />
        </Tooltip>
      }
    },
    permission: (onEditPermission) => {
      return {
        key: 'permission',
        title: 'Droit',
        dataIndex: 'permission',
        render: (text, user) => <Tooltip
          title="Droit"
          placement="bottom"
          mouseEnterDelay={0.6}
        >
          <Button
            icon={<EditOutlined/>}
            onClick={() => onEditPermission(text, user)}
          />
        </Tooltip>
      }
    },
    updatedAt: {
      title: 'Dernière mise à jour',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: (a, b) => b.updated_at - a.updated_at,
      render: (text, user) => user.updated_at
    }
  }

  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      showModal: false,
      groupUser: {
        name: '',
        users: []
      },
      user: {
        id: null,
        type: null,
        name: null,
        phone: null,
        email: null,
        password: null,
        last_name: null,
        first_name: null,
        updated_at: null,
        created_at: null
      },
      titleModal: 'Ajout',
      permission: {},
      notification: {
        show: false,
        type: 'success',
        message: null
      },
      actionToShow: ACTION.ADD_USER
    }
  }

  handleOpenModal = actionToShow => this.setState({
    actionToShow,
    showModal: true
  })

  handleCloseModal = () => {
    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        id: null,
        name: null,
        type: null,
        phone: null,
        email: null,
        password: null,
        last_name: null,
        first_name: null,
        updated_at: null,
        created_at: null
      },
      showModal: false,
      actionToShow: ACTION.ADD_USER
    }))
  }

  onEdit = user => this.setState({user, titleModal: 'Editer'}, () => this.handleOpenModal(ACTION.EDIT_USER))

  onAdd = () => {
    this.setState({titleModal: 'Ajout'}, () => {
      this.handleOpenModal(ACTION.ADD_USER)
    })
  }

  handleOk = () => {

  }

  onAddUser = values => {
    this.setState({loading: true})

    LoginService.register(values).then(async ({data}) => {
      this.setState({showModal: false}, async () => {
          await openMessage({content: 'Ajout...', contentAfter: data.message})
          this.props.onRefresh()
        }
      )
    }).catch(async ({response}) => {
      const message = this.showErrors(response.data.errors)

      this.setState(prevState => ({
        ...prevState,
        notification: {
          show: true,
          type: 'error',
          message
        }
      }))
      await openMessage({content: 'Ajout...', contentAfter: message})
    }).finally(() => this.setState({loading: false}))
  }

  onUpdateUser = values => {
    this.setState({loading: true})

    ProfileService.updateProfile(this.state.user.id, values).then(async ({data}) => {
      this.setState(prevState => ({
        ...prevState,
        showModal: false,
        notification: {
          show: true,
          message: data.message
        }
      }), async () => {
        await openMessage({content: 'Mise à jour...', contentAfter: data.message})
        this.props.onRefresh()
      })
    }).catch(async ({response}) => {
      const {errors} = response.data
      if (errors.length > 0) {
        await openMessage({content: 'Mise à jour...', contentAfter: this.showErrors(errors)})
      }
    }).finally(() => this.setState({loading: false}))
  }

  showErrors = errors => {
    let msg = ''
    if (errors.email) msg = errors.email.join(' ')
    else if (errors.first_name) msg = errors.first_name.join(' ')
    else if (errors.last_name) msg = errors.last_name.join(' ')
    else if (errors.phone) msg = errors.phone.join(' ')
    else if (errors.password) msg = errors.password.join(' ')
    return msg
  }

  onEditUserName = groupUser => {
    this.setState({titleModal: 'Utilisateurs', groupUser}, () => {
      this.handleOpenModal(ACTION.LIST_USERS)
    })
  }

  onEditPermission = (text, groupUser) => {
    this.setState({titleModal: 'Droits', groupUser}, () => {
      this.handleOpenModal(ACTION.PERMISSION_USER)
    })
  }

  render () {
    const columns = [
      GroupUserTable.ColumnModel.name(this.props.onNameEdit),
      GroupUserTable.ColumnModel.user(this.onEditUserName),
      GroupUserTable.ColumnModel.permission(this.onEditPermission),
      GroupUserTable.ColumnModel.updatedAt
    ]
    const {openPreview, dataSource, onRefresh, onNameEdit} = this.props
    const {groupUser, titleModal, showModal, actionToShow} = this.state

    return (
      <div>
        {/*TItle content*/}
        <TitleBar
          title="Groupe d'utilisateurs"
        />

        {/*page content*/}
        <MediaTable
          {...this.props}
          columns={columns}
          onAdd={this.onAdd}
          onRefresh={onRefresh}
          dataSource={dataSource}
          onPreview={openPreview}
          onEditName={onNameEdit}
        />

        {/*Modal to Add or Edit info user*/}
        {showModal && <ModalContent
          style={null}
          width={600}
          footer={null}
          title={titleModal}
          visible={showModal}
          handleOk={this.handleOk}
          handleCancel={this.handleCloseModal}
        >
          <div>
            {actionToShow === ACTION.LIST_USERS && <ModuleFormHelper users={groupUser.users}/>}
            {actionToShow === ACTION.PERMISSION_USER && <PermissionFormHelper groupUser={groupUser} handleCloseModal={this.handleCloseModal}/>}
          </div>
        </ModalContent>}
      </div>
    )
  }
}

const PermissionFormHelper = React.memo(({groupUser, handleCloseModal}) => {
  const [formData, setFormData] = useState({
    ...groupUser, ...{
      apply_file_to_all_users: false,
      apply_scene_to_all_users: false,
      apply_agenda_to_all_users: false,
      apply_module_to_all_users: false
    }
  })
  const [current, setCurrent] = useState('file')
  const {loading: loadingPermission, putData} = useFetch()

  const handleClick = e => setCurrent(e.key)

  const onFinish = () => {
    putData(groupUser.id, routesBackend.GROUP_USERS.EDIT, formData).then(async ({data}) => {
      setFormData(data.group)
      await openMessage({content: 'Mise à jour', contentAfter: 'Mise à jour effectuée avec succès.'})
      handleCloseModal({reloadable: true})
    }).catch(console.error)
  }

  return (
    <div>
      <Menu style={{marging: 0}} onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="file" icon={<MailOutlined/>}>
          Fichier
        </Menu.Item>
        <Menu.Item key="scene" icon={<AppstoreOutlined/>}>
          Scenes
        </Menu.Item>
        <Menu.Item key="agenda" icon={<AppstoreOutlined/>}>
          Agendas
        </Menu.Item>
        <Menu.Item key="user" icon={<AppstoreOutlined/>}>
          Modules
        </Menu.Item>
      </Menu>

      {loadingPermission ? <Loader/> : <Form onFinish={onFinish}>
        <Row style={{margin: '1rem 1rem'}}>
          {current === 'file' && <>
            <Row>
              <Col>
                <Checkbox
                  checked={formData.create_file}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    create_file: e.target.checked
                  }))}
                >Créer un Fichier</Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={formData.update_file}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    update_file: e.target.checked
                  }))}
                >Modifier un Fichier</Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={formData.delete_file}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    delete_file: e.target.checked
                  }))}>Supprimer un Fichier</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col>
                <br/>
                <Checkbox
                  checked={formData.apply_file_to_all_users}
                  onChange={(e) => setFormData(state => ({...state, apply_file_to_all_users: e.target.checked}))}
                >Appliquer aux membres actuels du groupe ?</Checkbox>
              </Col>
            </Row>
          </>}
          {current === 'scene' && <>
            <Row>
              <Col>
                <Checkbox
                  checked={formData.create_scene}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    create_scene: e.target.checked
                  }))}>Créer une Scene</Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={formData.update_scene}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    update_scene: e.target.checked
                  }))}>Modifier une Scene</Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={formData.delete_scene}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    delete_scene: e.target.checked
                  }))}>Supprimer une Scene</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col>
                <br/>
                <Checkbox
                  checked={formData.apply_scene_to_all_users}
                  onChange={(e) => setFormData(state => ({...state, apply_scene_to_all_users: e.target.checked}))}
                >Appliquer aux membres actuels du groupe ?</Checkbox>
              </Col>
            </Row>
          </>}
          {current === 'agenda' && <>
            <Row>
              <Col>
                <Checkbox
                  checked={formData.create_agenda}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    create_agenda: e.target.checked
                  }))}>Créer un Agenda</Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={formData.update_agenda}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    update_agenda: e.target.checked
                  }))}>Modifier un Agenda</Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={formData.delete_agenda}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    delete_agenda: e.target.checked
                  }))}>Supprimer un Agenda</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col>
                <br/>
                <Checkbox
                  checked={formData.apply_agenda_to_all_users}
                  onChange={(e) => setFormData(state => ({...state, apply_agenda_to_all_users: e.target.checked}))}
                >Appliquer aux membres actuels du groupe ?</Checkbox>
              </Col>
            </Row>
          </>}
          {current === 'user' && <>
            <Row>
              <Col>
                <Checkbox
                  checked={formData.create_module}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    create_module: e.target.checked
                  }))}>Créer un Module</Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={formData.update_module}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    update_module: e.target.checked
                  }))}>Modifier l'état d'un Module</Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={formData.delete_module}
                  onChange={(e) => setFormData(state => ({
                    ...state,
                    delete_module: e.target.checked
                  }))}>Supprimer un Module</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col>
                <br/>
                <Checkbox
                  checked={formData.apply_module_to_all_users}
                  onChange={(e) => setFormData(state => ({...state, apply_module_to_all_users: e.target.checked}))}
                >Appliquer aux membres actuels du groupe ?</Checkbox>
              </Col>
            </Row>
          </>}
        </Row>

        <Row style={{margin: '1rem 1rem'}}>
          <Col>
            <Space>
              <Popconfirm
                okText="Oui"
                cancelText="Non"
                onConfirm={onFinish}
                title="Êtes-vous sûr de vouloir soumettre le formulaire?"
              >
                <Button type="primary" htmlType="submit" loading={loadingPermission}>Soumettre</Button>
              </Popconfirm>
              <Button htmlType="button" onClick={handleCloseModal}>Fermer</Button>
            </Space>
          </Col>
        </Row>
      </Form>}
    </div>
  )
})

const ModuleFormHelper = React.memo(({users, handleCloseModal}) => {
  const {loading: loadingUsers} = useFetch()
  const [current, setCurrent] = useState('users')

  const handleClick = e => setCurrent(e.key)

  const onFinish = values => {
  }

  const onChange = checkedValues => {
    console.log('checked = ', checkedValues)
  }

  return (
    <div>
      <Menu style={{marging: 0}} onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="users">
          Utilisateurs
        </Menu.Item>
      </Menu>

      {loadingUsers ? <Loader/> : <>
        <Checkbox.Group style={{marginTop: '1rem', width: '100%'}} onChange={onChange}>
          <Row>
            {users.map(user => (
              <Col style={{textAlign: 'left'}} span={8} key={user.id}>
                <Checkbox value={user.name}>{user.name}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>

        <Row style={{display: 'flex', alignItems: 'end', justifyContent: 'end', margin: '1rem 0 0 0'}}>
          <Space>
            <Popconfirm
              okText="Oui"
              cancelText="Non"
              onConfirm={onFinish}
              title="Êtes-vous sûr de vouloir soumettre le formulaire?"
            >
              <Button type="primary" htmlType="submit" loading={loadingUsers}>Soumettre</Button>
            </Popconfirm>
            <Button htmlType="button" onClick={handleCloseModal}>Fermer</Button>
          </Space>
        </Row>
      </>}
    </div>
  )
})
