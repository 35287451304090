import React from 'react'
import PropTypes from 'prop-types'
import {
  PlusOutlined,
  DeleteOutlined,
  ReloadOutlined,
  UserAddOutlined,
  CalendarOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'
import {
  Table,
  Input,
  Button,
  Row,
  Col,
  Tooltip,
  Popconfirm
} from 'antd'

import TitleBar from '../TitleBar'
import ActionsCell from './ActionsCell'
import './DataTable.scss'

export default class DataTable extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool,
    onAdd: PropTypes.func,
    onCalendar: PropTypes.func,
    onCalendarSelection: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteSelection: PropTypes.func,
    onEdit: PropTypes.func,
    onGroupSelection: PropTypes.func,
    onPreview: PropTypes.func,
    onRefresh: PropTypes.func,
    onSchedule: PropTypes.func,
    onScheduleSelection: PropTypes.func,
    onSearch: PropTypes.func,
    title: PropTypes.string
  }

  constructor (props) {
    super(props)

    this.state = {
      groupNameInputValue: ''
    }
  }

  render () {
    const {
      title,
      onEdit,
      onlock,
      onAdd,
      loading,
      columns,
      onSearch,
      onDelete,
      onUnlock,
      onScene,
      onPreview,
      onRefresh,
      onSchedule,
      dataSource,
      onCalendar,
      rowSelection,
      onGroupSelection,
      onDeleteSelection,
      onScheduleSelection,
      onCalendarSelection,
      ...otherProps
    } = this.props

    const tableColumns = (onCalendar || onlock || onUnlock || onDelete || onEdit || onPreview || onSchedule ? columns.concat({
      key: 'actions',
      title: 'Actions',
      className: 'maw-data-table-actions-column',
      render: (text, record) => <ActionsCell
        onEdit={onEdit}
        onlock={onlock}
        record={record}
        onScene={onScene}
        onDelete={onDelete}
        onUnlock={onUnlock}
        onPreview={onPreview}
        onCalendar={onCalendar}
        onSchedule={onSchedule}
        permission={this.props.permission}
        permissions={this.props.permissions}
      />
    }) : columns)

    return (
      <div className="maw-data-table">
        {title && <TitleBar title={title}/>}

        <div style={{display: 'none'}} id="divalert"/>

        {(onRefresh || onGroupSelection || onDeleteSelection || onAdd || onSearch || onScheduleSelection || onCalendarSelection) && (
          <Row style={{marginBottom: '1.5rem'}}>
            <Col span={14} className="maw-data-table-tool-bar">

              {onRefresh && <Tooltip
                placement="bottom"
                mouseEnterDelay={0.6}
                title="Rafraichir les données"
              >
                <Button
                  shape="circle"
                  loading={loading}
                  onClick={onRefresh}
                  icon={<ReloadOutlined/>}
                />
              </Tooltip>}

              {onGroupSelection && <Tooltip
                placement="bottom"
                mouseEnterDelay={0.6}
                title="Groupe la sélection"
              >
                <Button
                  shape="circle"
                  icon={<UserAddOutlined/>}
                  onClick={() => this.setState({groupPopoverVisible: true})}
                />
              </Tooltip>}

              {onScheduleSelection && <Tooltip
                placement="bottom"
                mouseEnterDelay={0.6}
                title="Editer les horraires d'activité"
              >
                <Button
                  shape="circle"
                  icon={<ClockCircleOutlined/>}
                  onClick={onScheduleSelection}
                />
              </Tooltip>}

              {onCalendarSelection && <Tooltip
                placement="bottom"
                mouseEnterDelay={0.6}
                title="Modifier l'agenda de la sélection"
              >
                <Button
                  shape="circle"
                  icon={<CalendarOutlined/>}
                  onClick={onCalendarSelection}
                />
              </Tooltip>}

              {onDeleteSelection && <Tooltip
                placement="bottom"
                mouseEnterDelay={0.6}
                title="Supprimer la sélection"
              >
                <Popconfirm
                  title={() => (<p style={{textAlign: 'center'}}>
                    La suppression d'un/des <br/> média(s) peut entraîner <br/> un dysfonctionnement <br/> sur les agendas qui l'utilisent.<br/>
                    Êtes-vous sûr ?
                  </p>)}
                  onConfirm={onDeleteSelection}
                  okText="Oui"
                  cancelText="Non"
                >
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined/>}
                    disabled={this.props.permission && !this.props.permission.includes(this.props.permissions && this.props.permissions.delete)}
                  />
                </Popconfirm>
              </Tooltip>}

              {onAdd && <Tooltip
                title="Ajouter"
                placement="bottom"
                mouseEnterDelay={0.6}
              >
                <Button
                  shape="circle"
                  type="primary"
                  onClick={onAdd}
                  icon={<PlusOutlined/>}
                  disabled={this.props.permission && !this.props.permission.includes(this.props.permissions && this.props.permissions.create)}
                />
              </Tooltip>}
            </Col>

            <Col span={10}>
              {onSearch && <Input.Search
                allowClear
                enterButton
                onSearch={onSearch}
                placeholder="Rechercher ..."
              />}
            </Col>
          </Row>
        )}

        <Table
          {...otherProps}
          size="small"
          loading={loading}
          rowKey={r => r.id}
          columns={tableColumns}
          dataSource={dataSource}
          rowSelection={rowSelection}
          locale={{emptyText: 'Pas de données disponible.'}}
        />
      </div>
    )
  }
}
