import {message} from 'antd'

export const openMessage = async (props) => {
  const {
    cb,
    duration = 100,
    type = 'success',
    durationLoading = .5,
    content = 'Chargement...',
    contentAfter = 'Charger!'
  } = props

  const key = 'updatable'
  await message.loading({content, key, duration: durationLoading})
  const interval = () => {
    return type === 'success'
      ? message.success({content: contentAfter, key, duration: 3})
      : (
        type === 'warning'
          ? message.warning({content: contentAfter, key, duration: 3})
          : message.error({content: contentAfter, key, duration: 3})
      )
  }

  setTimeout(() => {
    interval()
    cb && cb()
  }, duration)
}

export const toStartZero = value => value <= 9 ? `0${value}` : `${value}`
export const toDateMinusOne = dateStr => {
  const date = new Date(dateStr)
  return `${date.getFullYear()}-${toStartZero((date.getMonth() + 1))}-${toStartZero(date.getDate() - 1)}`
}
export const messageLoad = async ({message = 'Chargement ...', duration = 2.5}) => await message.loading(message, duration)
export const messageSucces = async (message = 'Chargement ...', duration = 2.5) => await message.success(message, duration)
export const messageError = async (message = 'Chargement ...', duration = 2.5) => await message.error(message, duration)

export function reload (duration = 500) {
  window.setTimeout(() => {
    document.location.reload()
  }, duration)
}

export const detailFile = (weight) => {
  if (weight < 1000) return `${weight} o`
  else if (weight < 1000000) return `${Number(weight / 1000).toFixed(2)} Ko`
  else if (weight < 1000000000) return `${Number(weight / 1000000).toFixed(2)} Mo`

  return `${Number(weight / 1000000000).toFixed(2)} Go`
}

export const sceneJsonParse = arrayable => {
  let obj = {}
  arrayable.map((ar) => obj[ Object.values(ar)[0] ] = ar);
  return obj;
}
