import resource from '../utils/resource'

const AxiosService = {
  /**
   * Get all files for one entity
   *
   * @returns {Promise<*>}
   */
  get: async (url, config = null) => await resource.get(url, config),
  /**
   * Save files
   *
   * @returns {Promise<*>}
   */
  post: async (url, data: Object, config = null) => await resource.post(url, data, config),
  /**
   * Get one file
   *
   * @returns {Promise<*>}
   */
  put: async (id: Number, url: String, data: Object, config = null) => await resource.put(`${url}/${id}`, data, config),
  /**
   * Delete a file
   *
   * @returns {Promise<*>}
   */
  destroy: async (id: Number, url: String, config = null) => await resource.delete(`${url}/${id}`, config)
}

export default AxiosService
